import { v4 } from 'uuid';
import type { ReactNode } from 'react';
import { type SnackbarMessage, snackbarsVar } from '@src/apollo/local';

export class SnackbarMessenger {
  static defaultOptions() {
    return {
      autoHideDuration: 3000,
      createdAt: new Date(),
      id: v4(),
      close: true,
    };
  }

  addErrorMessage(
    message: ReactNode,
    overrides: Partial<Omit<SnackbarMessage, 'color'>> = {}
  ) {
    snackbarsVar([
      ...snackbarsVar(),
      {
        ...SnackbarMessenger.defaultOptions(),
        message,
        color: 'danger',
        ...overrides,
      },
    ]);
  }

  addSuccessMessage(
    message: ReactNode,
    overrides: Partial<Omit<SnackbarMessage, 'color'>> = {}
  ) {
    snackbarsVar([
      ...snackbarsVar(),
      {
        ...SnackbarMessenger.defaultOptions(),
        message,
        color: 'success',
        ...overrides,
      },
    ]);
  }

  // Use when you want to replace any previous messages
  setErrorMessage(
    message: ReactNode,
    overrides: Partial<SnackbarMessage> = {}
  ) {
    snackbarsVar([
      {
        ...SnackbarMessenger.defaultOptions(),
        message,
        color: 'danger',
        close: false,
        ...overrides,
      },
    ]);
  }

  addLinkMessage(message: ReactNode, overrides: Partial<SnackbarMessage> = {}) {
    snackbarsVar([
      {
        ...SnackbarMessenger.defaultOptions(),
        message,
        color: 'link',
        close: false,
        ...overrides,
      },
    ]);
  }
}
