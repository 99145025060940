import type { ReactNode } from 'react';
import * as colors from '@src/support/colors';
import { FontAwesomeIcon } from '../font-awesome-icon';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { rgba } from '@src/support/rgba';

type Props = {
  title: string;
  icon: IconProp;
  actions: ReactNode;
};

export function ListItem({ title, icon, actions }: Props) {
  return (
    <div className="ListItem">
      <div>
        <span className="ListItem-icon">
          <FontAwesomeIcon icon={icon} size="xs" />
        </span>
        <span className="ListItem-title">{title}</span>
      </div>
      <div className="ListItem-actions">{actions}</div>
      <style jsx>{`
        .ListItem {
          padding: 0.625em;
          display: flex;
          background-color: ${colors.white};
          box-shadow: 0px 1px 3px ${rgba(colors.black, 0.15)};
          margin-bottom: 1px;
          justify-content: space-between;
        }

        .ListItem-title {
          font-size: 0.8125em;
          font-weight: bold;
          margin-left: 0.625em;
        }
      `}</style>
    </div>
  );
}
