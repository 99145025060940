import type { ReactNode, ComponentProps, PropsWithChildren } from 'react';
import { type FieldValidator, Field } from 'formik';
import { getHelperText, hasError } from './utils';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core';
import { FormikCheckboxFieldContext } from './formik-checkbox-field-context';

type Props = {
  disabled?: boolean;
  FormControlProps?: any;
  FormLabelProps?: any;
  fullWidth?: boolean;
  helperText?: string;
  innerRef?: any;
  label?: string | ReactNode;
  margin?: 'none' | 'dense' | 'normal';
  name: string;
  FormGroupProps?: any;
  required?: boolean;
  row?: boolean;
  validate?: FieldValidator;
  variant?: 'standard' | 'outlined' | 'filled';
  classes?: ComponentProps<typeof FormControl>['classes'];
};

export function FormikCheckboxField({
  children,
  disabled,
  FormControlProps,
  FormLabelProps,
  fullWidth,
  helperText,
  innerRef,
  label,
  margin,
  name,
  FormGroupProps,
  required,
  row,
  validate,
  variant,
  classes,
}: PropsWithChildren<Props>) {
  return (
    <FormikCheckboxFieldContext.Provider value={{ name }}>
      <Field name={name} innerRef={innerRef} validate={validate}>
        {({ field, form }: any) => {
          return (
            <FormControl
              component="fieldset"
              disabled={disabled}
              fullWidth={fullWidth}
              margin={margin}
              variant={variant}
              required={required}
              error={hasError(field.name, form.touched, form.errors)}
              {...FormControlProps}
              classes={classes}
            >
              <FormLabel
                component="legend"
                {...Object.assign({ shrink: 'true' }, FormLabelProps)}
              >
                {label}
              </FormLabel>
              <FormGroup row={row} {...FormGroupProps}>
                {children}
              </FormGroup>
              {hasError(field.name, form.touched, form.errors) || helperText ? (
                <FormHelperText>
                  {getHelperText(
                    field.name,
                    form.touched,
                    form.errors,
                    helperText
                  )}
                </FormHelperText>
              ) : null}
            </FormControl>
          );
        }}
      </Field>
    </FormikCheckboxFieldContext.Provider>
  );
}
