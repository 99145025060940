import { useCallback, useMemo } from 'react';
import type { GetServerSidePropsContext } from 'next';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createApolloClient } from '@src/apollo';
import { useRouter } from 'next/router';
import { resetSessionToLogin, ssrResetSessionToLogin } from '@src/lib/auth';
import { useSnackbarMessenger } from '@src/hooks';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export function initializeApollo(
  initialState: NormalizedCacheObject = {},
  onUnauthenticatedError: () => void
) {
  const _apolloClient =
    apolloClient ?? createApolloClient(onUnauthenticatedError);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState: NormalizedCacheObject) {
  const router = useRouter();
  const [snackbar] = useSnackbarMessenger();

  const onUnauthenticatedError = useCallback(() => {
    // We use window.location rather than router because the apollo client is a singleton
    // It means that this callback is never updated
    // TODO: Remove when no more authenticated queries on signup route & facility.
    const path = window.location.pathname;
    if (path.startsWith('/signup') || path.startsWith('/facility')) {
      return;
    }

    snackbar?.setErrorMessage('Your session has expired.');
    resetSessionToLogin(router);
  }, [snackbar, router]);

  return useMemo(
    () => initializeApollo(initialState, onUnauthenticatedError),
    [initialState, onUnauthenticatedError]
  );
}

export const ssrInitializeApollo = (context: GetServerSidePropsContext) =>
  initializeApollo({}, () => ssrResetSessionToLogin(context));

export const ssgInitializeApollo = () =>
  initializeApollo({}, () => {
    throw new Error('Unauthenticated');
  });
