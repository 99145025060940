import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ChatContext,
  type PanelState,
} from '@src/contexts/chat-provider/chat-context';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { WebChatDataDocument } from '@generated/graphql';
import { isUser } from '@src/utils/user-utils';
import * as SunshineService from '@src/lib/sunshine';
import { ChatPanel } from '@src/components/panels/chat-panel';

export const WebChatProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<PanelState>({ open: false });
  const { data, refetch } = useQuery(WebChatDataDocument);
  const user = data?.viewer;
  const unreadCount = data?.sunshineUnreadCount || 0;

  const openSunshine = useCallback(
    (props?: Omit<PanelState, 'open'>) => {
      if (isUser(user)) {
        SunshineService.init(user);
      }

      setState({ open: true, ...(props || {}) });
    },
    [user]
  );

  const closeSunshine = useCallback(() => {
    refetch();
    SunshineService.destroy();
    setState({ open: false });
  }, [refetch]);

  // These are to preserve current behavior: There's some internal
  // shenanigans where we mix sunshine with our own impls in
  // ChatPanel, for those cases we don't want to lose
  // the initial topic & subtopic (to not reset key)
  const suspendOpenSunshine = useCallback(() => {
    if (isUser(user)) {
      SunshineService.init(user);
    }

    setState(data => ({ ...data, open: true }));
  }, [user]);

  const suspendCloseSunshine = useCallback(() => {
    refetch();
    SunshineService.destroy();
    setState(data => ({ ...data, open: false }));
  }, [refetch]);

  useEffect(() => {
    if (!state.open) {
      return;
    }

    window.TrustedHealthSunshine?.on?.('widget:closed', closeSunshine);

    return () =>
      window.TrustedHealthSunshine?.off?.('widget:closed', closeSunshine);
  }, [closeSunshine, state.open]);

  const value = useMemo(
    () => ({
      unreadCount,
      open: openSunshine,
      close: closeSunshine,
    }),
    [closeSunshine, unreadCount, openSunshine]
  );

  const cleanStateKey =
    state.topic && state.subTopic
      ? `${state.topic}:${state.subTopic}`
      : 'default';

  return (
    <ChatContext.Provider value={value}>
      {children}
      {isUser(user) && (
        <ChatPanel
          key={cleanStateKey}
          initialTopic={state.topic}
          initialSubtopic={state.subTopic}
          user={user}
          isOpen={state.open}
          openSunshine={suspendOpenSunshine}
          onClose={suspendCloseSunshine}
        />
      )}
    </ChatContext.Provider>
  );
};
