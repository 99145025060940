import type { PropsWithChildren } from 'react';

import * as colors from '@src/support/colors';

type Props = {
  position?: 'left' | 'right';
};

export const NavMainHeaderBarActions = ({
  children,
  position = 'right',
}: PropsWithChildren<Props>) => {
  return (
    <div className="NavMainHeaderBarActions NavMainHeaderBarActions--color-primary">
      {children}
      <style jsx>{`
        .NavMainHeaderBarActions {
          display: flex;
          align-items: center;
          justify-content: ${position === 'left' ? 'flex-start' : 'flex-end'};
          appearance: none;
          background-color: inherit;
          border-width: 0;
          height: 100%;
          gap: 0.5rem;
          flex: 1;
        }

        .NavMainHeaderBarActions--color-primary {
          color: ${colors.neutralHighest};
        }
      `}</style>
    </div>
  );
};
