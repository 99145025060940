/* eslint-disable filenames/match-exported */
import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';
import { print } from 'graphql';
import getConfig from 'next/config';
import { some } from 'lodash';
import {
  getGqlHeaders,
  handleGraphQLError,
  handleNetworkError,
} from '@src/utils/network';
import { CookieName, getCookie } from '@src/utils/cookies';
import { defaultOptions } from './client-options';
import { cacheOptions } from './cache';

const { API_HOST, GRAPHQL_MULTIPLEXING_ENABLED, NODE_ENV } =
  getConfig().publicRuntimeConfig;

export const createApolloClient = (onUnauthenticatedError: () => void) => {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  const ssrMode = typeof window === 'undefined';

  const linkOpts = {
    uri: `${API_HOST}/graphql`, // Server URL (must be absolute)
    credentials: 'omit',
  };

  // Since we can only use one terminating link but we want to support
  // both request batching and multipart uploads, we'll use a split
  // and only use the upload link if the request has files in it
  const uploadLink = createUploadLink(linkOpts);
  let terminatingLink: ApolloLink = uploadLink;

  if (
    GRAPHQL_MULTIPLEXING_ENABLED &&
    // MSW doesn't support batched GQL
    NODE_ENV !== 'test'
  ) {
    terminatingLink = ApolloLink.split(
      operation => extractFiles(operation).files.size > 0,
      uploadLink,
      new BatchHttpLink(linkOpts)
    );
  }

  const authLink = setContext((_, previousContext) => {
    return {
      ...previousContext,
      headers: {
        ...getGqlHeaders(),
        ...previousContext.headers,
        ...getMobileHeaders(),
      },
    };
  });

  const getMobileHeaders = () => {
    const mobileCookies = getCookie(CookieName.Mobile);
    const mobileCookiesObj = mobileCookies ? JSON.parse(mobileCookies) : null;

    return mobileCookiesObj
      ? {
          'Trusted-Mobile-Version':
            mobileCookiesObj?.['Trusted-Mobile-Version'] || '',
          'Trusted-Mobile-Platform':
            mobileCookiesObj?.['Trusted-Mobile-Platform'] || '',
          'Trusted-Mobile-AppsFlyerId':
            mobileCookiesObj?.['Trusted-Mobile-AppsFlyerId'] || '',
        }
      : {};
  };

  const errorLink = onError(error => {
    const { graphQLErrors, operation, networkError } = error;

    const hasUnauthenticatedError = some(
      graphQLErrors || [],
      graphQLError => graphQLError.extensions?.code === 'UNAUTHENTICATED'
    );

    if (hasUnauthenticatedError) {
      onUnauthenticatedError();
    }

    if (graphQLErrors) {
      handleGraphQLError(
        graphQLErrors,
        print(operation.query),
        operation.variables,
        operation.getContext()
      );
    }

    if (networkError) {
      handleNetworkError(
        networkError,
        print(operation.query),
        operation.variables,
        operation.getContext()
      );
    }
  });

  const link = ApolloLink.from([authLink, errorLink, terminatingLink]);

  return new ApolloClient({
    defaultOptions,
    assumeImmutableResults: true,
    connectToDevTools: !ssrMode && NODE_ENV !== 'production',
    ssrMode,
    name: 'trusted-web',
    version: '1.0.0',
    link,
    cache: new InMemoryCache(cacheOptions),
  });
};
