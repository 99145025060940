import { FieldArray, useFormikContext } from 'formik';

import * as colors from '@src/support/colors';
import { getLocationPlaceholder } from '@src/utils/presentation';
import {
  FormikCheckboxField,
  FormikCheckboxFieldOption,
} from '@src/components/formik';
import { GooglePlacesAutoComplete } from '../google-places-auto-complete';
import { FontAwesomeIcon, ListItem, ListItemAction } from '@src/ui';
import { WelcomeBackModalSelectorContainer } from './welcome-back-modal-selector-container';
import { WelcomeBackModalDropdownContainer } from './welcome-back-modal-dropdown-container';
import { InfoType } from '@src/constants/info_modals';
import { GoogleMapsScriptProvider } from '@src/contexts/google-maps-script-provider';

type Props = {
  open: boolean;
  onInfoClick: (infoType: InfoType) => void;
  onToggle: () => void;
  isNursing: boolean;
};

export interface WelcomeBackModalLocationValues {
  broadPrefLocations: readonly string[];
  locationStrings: string[];
}

const welcomeBackInfoRowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};

export function WelcomeBackModalLocation({
  open,
  onInfoClick,
  onToggle,
  isNursing,
}: Props) {
  const { values, errors } = useFormikContext<WelcomeBackModalLocationValues>();
  return (
    <>
      <WelcomeBackModalDropdownContainer>
        <p className="WelcomeBackModalLocationDropdownLabel">Location</p>
        <div
          className="WelcomeBackModalLocationDropdownOption"
          onClick={onToggle}
        >
          {open ? (
            <p style={{ color: colors.neutralHighest, fontWeight: 400 }}>
              Select
            </p>
          ) : (
            <p style={{ color: colors.neutralHighest1 }}>
              {getLocationPlaceholder(
                values.broadPrefLocations,
                values.locationStrings
              )}
            </p>
          )}
          <span className="WelcomeBackModalLocationDropdownOptionIcon">
            <FontAwesomeIcon
              icon={open ? ['fas', 'caret-up'] : ['fas', 'caret-down']}
              color={colors.neutralHighest1}
            />
          </span>
        </div>
      </WelcomeBackModalDropdownContainer>

      {open && (
        <WelcomeBackModalSelectorContainer>
          <FormikCheckboxField name="broadPrefLocations" fullWidth>
            <div className="WelcomeBackModalLocationOptions">
              {isNursing && (
                <div style={welcomeBackInfoRowStyle}>
                  <>
                    <FormikCheckboxFieldOption
                      value="compact_states"
                      label="All compact states"
                    />
                    <div style={{ padding: '0.8125em 0.5625em 0.5625em' }}>
                      <FontAwesomeIcon
                        icon={['fal', 'info-circle']}
                        onClick={() => {
                          onInfoClick(InfoType.COMPACT_STATE);
                        }}
                      />
                    </div>
                  </>
                </div>
              )}
              <div style={welcomeBackInfoRowStyle}>
                <FormikCheckboxFieldOption
                  value="specific_locations"
                  label="Specific location(s)"
                />
              </div>
            </div>
          </FormikCheckboxField>
          {values.broadPrefLocations.includes('specific_locations') && (
            <div className="WelcomeBackModalSpecificLocations">
              <FieldArray name="locationStrings">
                {({ name, push }) => {
                  return (
                    <GoogleMapsScriptProvider>
                      <GooglePlacesAutoComplete
                        name={name}
                        fullWidth
                        placeholder="+ add city and/or state"
                        error={!!errors.locationStrings}
                        inputProps={{
                          name: name,
                          style: { textIndent: '1em' },
                        }}
                        helperText={errors.locationStrings}
                        onChange={value => {
                          const valueIsUnique = values.locationStrings.every(
                            locationString =>
                              locationString !== value.description
                          );
                          if (valueIsUnique) {
                            push(value.description);
                          }
                        }}
                        hideCountryLabel
                      />
                    </GoogleMapsScriptProvider>
                  );
                }}
              </FieldArray>
              {values.locationStrings.length > 0 && (
                <FieldArray name="locationStrings">
                  {({ remove }) => (
                    <div style={{ marginTop: '0.25em' }}>
                      {values.locationStrings.map((locationString, index) => (
                        <ListItem
                          key={locationString}
                          title={locationString}
                          icon={['fal', 'map-marker-alt']}
                          actions={
                            <ListItemAction
                              icon={['fal', 'trash-alt']}
                              iconProps={{
                                style: {
                                  color: colors.neutralHighest1,
                                },
                              }}
                              onClick={() => remove(index)}
                            />
                          }
                        />
                      ))}
                    </div>
                  )}
                </FieldArray>
              )}
            </div>
          )}
        </WelcomeBackModalSelectorContainer>
      )}
      <style jsx>{`
        .WelcomeBackModalLocationDropdownLabel {
          font-weight: bold;
        }

        .WelcomeBackModalLocationDropdownOption {
          display: flex;
          font-weight: bold;
          cursor: pointer;
        }

        .WelcomeBackModalLocationOptions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 0.625em;
        }

        .WelcomeBackModalLocationDropdownOptionIcon {
          padding-left: 0.3125em;
        }

        .WelcomeBackModalSpecificLocations {
          padding: 0 0.9375em 1.25em 0.9375em;
        }
      `}</style>
    </>
  );
}
