import clsx from 'clsx';
import * as colors from '@src/support/colors';
import { ToggleSwitch } from '@src/ui';
import type { ChangeEventHandler } from 'react';

type Props = {
  onToggle: ChangeEventHandler<HTMLInputElement>;
  toggle: boolean;
  startPref?: string | null;
};

export function NavigationIntentSelector({
  onToggle,
  toggle,
  startPref,
}: Props) {
  return (
    <div className="NavigationIntentSelector">
      <div
        className={clsx('NavigationIntentSelectorText', {
          'NavigationIntentSelectorText-startPref-looking':
            startPref === 'asap' || startPref === 'specific_date',
        })}
      >
        {startPref === 'not_looking' || !startPref
          ? 'Not actively looking'
          : 'Looking for travel jobs'}
      </div>
      <div
        className={clsx('NavigationIntentSelectorToggle', {
          'NavigationIntentSelectorToggle-toggle-on': toggle,
        })}
      >
        <ToggleSwitch toggle={toggle} onToggle={onToggle} />
      </div>
      <style jsx>{`
        .NavigationIntentSelector {
          color: ${colors.neutralHighest};
          font-size: 1rem;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .NavigationIntentSelectorText {
          font-weight: 600;
        }

        .NavigationIntentSelectorToggle-toggle-on {
          padding-right: 0.4375em;
        }

        .NavigationIntentSelectorText-startPref-looking {
          color: ${colors.semanticSuccess};
        }
      `}</style>
    </div>
  );
}
