import type { PropsWithChildren } from 'react';

import * as colors from '@src/support/colors';

type Props = {
  smallerCopy?: boolean;
};

export function AlertMessage(props: PropsWithChildren<Props>) {
  return (
    <div className="AlertMessage">
      {props.children}
      <style jsx>{`
        .AlertMessage {
          font-size: ${props.smallerCopy ? '0.875rem' : '1rem'};
          line-height: ${20 / 16};
          color: ${colors.neutralHighest1};
        }
      `}</style>
    </div>
  );
}
