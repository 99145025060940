import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import {
  FontAwesomeIcon,
  Panel,
  PanelHeader,
  PanelHeaderButton,
  PanelHeaderTitle,
} from '@src/ui';
import { Formik } from 'formik';
import { TopicSelection } from './topic-selection';
import { SubtopicSelection } from './subtopic-selection';
import { HelpcenterArticle } from './helpcenter-article';
import { SubmitQuestion } from './submit-question';

import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  question: Yup.string()
    .matches(/[^\s]/, 'Message must contain a valid character')
    .required(),
});

type Props = {
  onSubmit: (...args: any[]) => any;
  backToConversationList: () => void;
  setLoading: (loading: boolean) => void;
  topic: string;
  setTopic: (topic: string) => void;
  subtopic: string;
  setSubtopic: (subtopic: string) => void;
  closePanel: () => void;
  setArticleOpen: Dispatch<SetStateAction<boolean>>;
  setArticleId: Dispatch<SetStateAction<string>>;
  setArticleName: Dispatch<SetStateAction<string>>;
  articleId: string;
  articleName: string;
};

export function DecisionTree({
  onSubmit,
  backToConversationList,
  setLoading,
  setTopic,
  setSubtopic,
  topic,
  subtopic,
  closePanel,
  setArticleOpen,
  setArticleId,
  setArticleName,
  articleId,
  articleName,
}: Props) {
  const [headerTitle, setHeaderTitle] = useState('');
  const [initialStep, setInitialStep] = useState(0);
  const [step, setStep] = useState(0);
  const [topicIcon, setTopicIcon] = useState<string>('angle-right');
  const [showFeedbackForm, setShowFeedbackForm] = useState<boolean>(true);
  const [collectionId, setCollectionId] = useState<string>('');
  const [sectionId, setSectionId] = useState<string>('');
  const [sectionParentType, setSectionParentType] = useState<string>('');

  useEffect(() => {
    const startStep = 1;
    setInitialStep(startStep);
    setStep(startStep);
  }, [setInitialStep, setStep]);

  const nextStep = () => {
    if (step === 2) {
      setShowFeedbackForm(true);
    }
    setStep(step + 1);
  };

  const backStep = () => {
    if (step > initialStep) {
      setStep(step - 1);
    } else {
      backToConversationList();
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        topic: topic,
        subtopic: subtopic,
        question: '',
        articleId: articleId,
        articleName: articleName,
      }}
      onSubmit={values => {
        onSubmit(values);
      }}
    >
      {({ handleSubmit }) => (
        <Panel
          header={
            <PanelHeader sticky>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <PanelHeaderButton type="button">
                    <FontAwesomeIcon
                      onClick={() => {
                        backStep();
                        setArticleOpen(false);
                      }}
                      icon={['fal', 'angle-left']}
                      fixedWidth
                    />
                  </PanelHeaderButton>
                  <PanelHeaderTitle
                    style={{
                      width: 230,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {headerTitle}
                  </PanelHeaderTitle>
                </div>
                <div>
                  <PanelHeaderButton type="button">
                    <FontAwesomeIcon
                      onClick={closePanel}
                      icon={['fal', 'times']}
                      size="sm"
                      fixedWidth
                    />
                  </PanelHeaderButton>
                </div>
              </div>
            </PanelHeader>
          }
        >
          <div className="PanelContainer" style={{ padding: '1em' }}>
            {step === 1 && (
              <TopicSelection
                setHeaderTitle={setHeaderTitle}
                setLoading={setLoading}
                onClick={topic => {
                  setTopic(topic);
                  nextStep();
                }}
                setTopicIcon={setTopicIcon}
                setCollectionId={setCollectionId}
              />
            )}

            {step === 2 && (
              <SubtopicSelection
                setHeaderTitle={setHeaderTitle}
                setLoading={setLoading}
                onClick={subtopic => {
                  setSubtopic(subtopic);
                  nextStep();
                }}
                topic={topic}
                topicIcon={topicIcon}
                collectionId={collectionId}
                setSectionId={setSectionId}
                setSectionParentType={setSectionParentType}
              />
            )}

            {step === 3 && (
              <SubmitQuestion
                topic={topic}
                subtopic={subtopic}
                setHeaderTitle={setHeaderTitle}
                setLoading={setLoading}
                handleSubmit={handleSubmit}
                onNext={nextStep}
                showFeedbackForm={showFeedbackForm}
                sectionId={sectionId}
                sectionParentType={sectionParentType}
                setArticleId={setArticleId}
                setArticleName={setArticleName}
                setArticleOpen={setArticleOpen}
              />
            )}

            {step === 4 && (
              <HelpcenterArticle
                setHeaderTitle={setHeaderTitle}
                setLoading={setLoading}
                backToConversationList={backToConversationList}
                backStep={backStep}
                showFeedbackForm={showFeedbackForm}
                setShowFeedbackForm={setShowFeedbackForm}
                articleId={articleId}
                setArticleOpen={setArticleOpen}
              />
            )}
          </div>
        </Panel>
      )}
    </Formik>
  );
}
