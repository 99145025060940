import Drawer, { type DrawerProps } from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';

import * as colors from '@src/support/colors';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { useIsMobile } from '@src/hooks/use-is-mobile';

const { NODE_ENV } = getConfig().publicRuntimeConfig;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: colors.white,
    [theme.breakpoints.up('sm')]: {
      width: '24.375em',
    },
  },
}));

const articleStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: colors.white,
    [theme.breakpoints.up('sm')]: {
      width: '43.25em',
    },
  },
}));

interface OwnProps {
  fullWidth?: boolean;
  articleOpen?: boolean;
}

type Props = OwnProps & DrawerProps;

export const ResponsiveDrawer = ({
  fullWidth,
  articleOpen,
  ...drawerProps
}: Props) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const articleClasses = articleStyles();
  const paperProps = Object.assign(
    !articleOpen ? { classes } : { articleClasses },
    drawerProps.PaperProps
  );

  const effectiveTransitionDuration = useMemo(() => {
    // Make transitions instant when running in test environments, to save ~300ms per panel action
    if (NODE_ENV === 'test') {
      return 0;
    }

    if (isMobile) {
      // Make back gesture on safari mobile less bad
      return drawerProps.open ? undefined : 0;
    }

    return undefined;
  }, [drawerProps.open, isMobile]);

  return (
    <Drawer
      {...drawerProps}
      PaperProps={!fullWidth ? paperProps : { classes }}
      transitionDuration={effectiveTransitionDuration}
    />
  );
};
