const ASAP_HEADER = 'ASAP?';
const ASAP_BODY =
  "I'm able to start an assignment as soon as I get an offer and can be onboarded.";
const SPECIFIC_DATE_HEADER = 'Specific date?';
const SPECIFIC_DATE_BODY =
  "I'm not available to start a new assignment before this date.";
const COMPACT_STATE_HEADER = 'Compact states?';
const COMPACT_STATE_BODY =
  'The Nursing Licensure Compact (NLC) agreement allows nurses with a compact license to practice in other states that are part of the agreement without having to obtain additional state licenses.';
const COMPACT_STATE_LINK_NAME = 'Learn more';
const COMPACT_STATE_LINK_URL = 'https://www.trustedhealth.com/compact-states';
const LOCAL_CONTRACTS_HEADER = 'Local Contracts';
const LOCAL_CONTRACTS_BODY =
  'Local contracts are jobs within a certain mile radius from your tax home. It does not include stipends and have no guaranteed hours, facilities may cancel your contract at anytime.';
const LOCAL_JOBS_HEADER = 'Local Only Jobs';
const LOCAL_JOBS_BODY =
  'Local Only jobs are opportunities that may be suitable for you if your tax home is within a certain proximity of the health care facility. If you live outside of the mileage proximity set by the facility, you will not be eligible for submission.';
const TIME_OFF_REQUESTS_HEADER = 'About Time-Off Requests!';
const TIME_OFF_REQUESTS_BODY =
  'Facilities need to know your time off requests when you apply to ensure that the position is a good match for your needs. We recommend requesting no more than 3-7 days to avoid potential delays in submission.';

export const enum InfoType {
  ASAP,
  SPECIFIC_DATE,
  COMPACT_STATE,
  LOCAL_CONTRACTS,
  LOCAL_JOBS,
  TIME_OFF_REQUESTS,
}

export function getInfoModalComponents(infoType: InfoType) {
  switch (infoType) {
    case InfoType.ASAP: {
      return {
        header: ASAP_HEADER,
        body: ASAP_BODY,
      };
    }
    case InfoType.SPECIFIC_DATE: {
      return {
        header: SPECIFIC_DATE_HEADER,
        body: SPECIFIC_DATE_BODY,
      };
    }
    case InfoType.LOCAL_CONTRACTS: {
      return {
        header: LOCAL_CONTRACTS_HEADER,
        body: LOCAL_CONTRACTS_BODY,
      };
    }
    case InfoType.LOCAL_JOBS: {
      return {
        header: LOCAL_JOBS_HEADER,
        body: LOCAL_JOBS_BODY,
      };
    }
    case InfoType.COMPACT_STATE: {
      return {
        header: COMPACT_STATE_HEADER,
        body: COMPACT_STATE_BODY,
        linkName: COMPACT_STATE_LINK_NAME,
        linkUrl: COMPACT_STATE_LINK_URL,
      };
    }
    case InfoType.TIME_OFF_REQUESTS: {
      return {
        header: TIME_OFF_REQUESTS_HEADER,
        body: TIME_OFF_REQUESTS_BODY,
      };
    }
    default: {
      return;
    }
  }
}
