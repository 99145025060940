import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { OtherTopics } from './other-topics';
import type { SubtopicItem } from './types';
import type { IconName } from '@fortawesome/fontawesome-common-types';
import { analytics } from '@src/lib/analytics';
import { IntercomSectionsDocument } from '@generated/graphql';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { notifyErrorCallback } from '@src/lib/error-reporter';
import { removeAmpersand } from '@src/utils/help-center';
import { HelpcenterCard } from './helpcenter-card';
import { SEGMENT_EVENTS } from '@src/constants';

type Props = {
  setHeaderTitle: (title: string) => void;
  setLoading: (loading: boolean) => void;
  onClick: (topic: string) => void;
  topicIcon: string;
  topic: string;
  collectionId: string;
  setSectionId: Dispatch<SetStateAction<string>>;
  setSectionParentType: Dispatch<SetStateAction<string>>;
};

export function SubtopicSelection({
  setHeaderTitle,
  setLoading,
  onClick,
  topicIcon,
  topic,
  collectionId,
  setSectionId,
  setSectionParentType,
}: Props) {
  const [subtopics, setSubtopics] = useState<SubtopicItem[]>([]);

  const { data, loading } = useQuery(IntercomSectionsDocument, {
    variables: { parentId: parseInt(collectionId) },
    onError: notifyErrorCallback('Subtopic Selection - Intercom section list'),
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setSubtopics(data?.sections as SubtopicItem[]);
    setHeaderTitle('Select Subtopic');
  }, [setHeaderTitle, data?.sections]);

  useEffect(() => setLoading(loading), [loading, setLoading]);

  return (
    <Box p={1}>
      <HelpcenterCard title={topic} icon={topicIcon as IconName}>
        {subtopics?.map((item, i) => (
          <OtherTopics
            key={i}
            topic={removeAmpersand(item.name)}
            onClick={() => {
              analytics.track(SEGMENT_EVENTS.CHAT_PANEL.SUBTOPIC_SELECTED, {
                source: 'Select Subtopic Page Web',
                subtopic: item.name,
              });
              onClick(removeAmpersand(item.name));
              setSectionId(item.id);
              setSectionParentType(item.type);
            }}
          />
        ))}
      </HelpcenterCard>
    </Box>
  );
}
