import { getCookie, setCookie, CookieName } from '@src/utils/cookies';

export const getGclidParam = () =>
  new URLSearchParams(window.location.search).get('gclid');

export const storeGclid = (gclid: string) => {
  setCookie(CookieName.Gclid, gclid, { domain: '.trustedhealth.com' });
};

export const getGclid = () => getCookie(CookieName.Gclid);
