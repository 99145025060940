import { FontAwesomeIcon } from '@src/ui/font-awesome-icon';
import * as colors from '@src/support/colors';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

type Props = {
  icon: IconProp;
  onClick: () => void;
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
};

export function ListItemAction({ icon, iconProps, onClick }: Props) {
  return (
    <a onClick={onClick} className="ListItemAction">
      <FontAwesomeIcon icon={icon} size="xs" {...iconProps} />
      <style jsx>{`
        .ListItemAction {
          color: ${colors.neutralHighest};
        }
      `}</style>
    </a>
  );
}
