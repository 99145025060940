import type { PropsWithChildren } from 'react';
import { NavMainHeaderTabs, NavMainHeaderTabsList } from '@src/ui';

export function NavigationTabs({ children }: PropsWithChildren<{}>) {
  return (
    <NavMainHeaderTabs>
      <NavMainHeaderTabsList>{children}</NavMainHeaderTabsList>
    </NavMainHeaderTabs>
  );
}
