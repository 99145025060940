import type { PropsWithChildren } from 'react';

export const NavMainHeaderTabsList = (props: PropsWithChildren<{}>) => (
  <ul className="NavMainHeaderTabsList">
    {props.children}
    <style jsx>
      {`
        .NavMainHeaderTabsList {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-grow: 1;
        }

        @media screen and (min-width: 24rem) {
          .NavMainHeaderTabsList {
            display: inline-flex;
            flex-grow: 0.5;
          }
        }
      `}
    </style>
  </ul>
);
