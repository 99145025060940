import { useRouter } from 'next/router';
import {
  type PropsWithChildren,
  type ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { isMobile as isMobileDevice } from 'react-device-detect';

import { NavigationDocument } from '@generated/graphql';
import TrustedLogomark from '@public/static/trusted_logomark_mint_black.svg';
import TrustedLogomarkFullBlack from '@public/static/trusted_wordmark_black.svg';
import { NavigationProfileDropdown } from '@src/components/navigation-profile-dropdown';
import { NavigationTopBarUser } from '@src/components/navigation/navigation-top-bar-user';
import { SystemMessage } from '@src/components/system-message';
import { WelcomeBackModal } from '@src/components/welcome-back-modal';
import { SEGMENT_EVENTS } from '@src/constants';
import { USER_VIEWED_CHAT_PANEL } from '@src/constants/segment-events/home';
import { useFeatureFlags } from '@src/contexts/feature-flags';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { useIsMobile } from '@src/hooks/use-is-mobile';
import { analytics } from '@src/lib/analytics';
import {
  Nav,
  NavMain,
  NavMainContent,
  NavMainHeader,
  NavMainHeaderBar,
  NavMainHeaderBarAction,
  NavMainHeaderBarActions,
  NavMainHeaderBarLogo,
  NavMainHeaderBarTitle,
  NavSidebar,
  NavSidebarCard,
  NavSidebarCardHeader,
  NavSidebarCardMenu,
  NavSidebarLogo,
} from '@src/ui/nav';
import { AdminSessionBanner } from '@src/ui/admin-session-banner';
import { FontAwesomeIcon } from '@src/ui/font-awesome-icon';
import { Scroll, ScrollView } from '@src/ui/scroll';
import { getUser } from '@src/utils/cookies';
import { nonNullValues } from '@src/utils/types';
import { maybeUser } from '@src/utils/user-utils';
import { useChat } from '@src/contexts/chat-provider';
import { useTopAppBarOverrides } from '@src/contexts/top-app-bar-overrides';
import { NavigationBottomBar } from '@src/components/navigation/navigation-bottom-bar';

import { CountBadge } from './count-badge';
import { NavigationBarLink } from './navigation-bar-link';
import { UserHomeState } from '../home-page/hooks';
import { NavigationSideMenu } from '@src/components/navigation/navigation-side-menu';
import type { NavigationLinkDefinition } from '@src/components/navigation/navigation-link-definition';
import { useBottomAppBarOverrides } from '@src/contexts/bottom-app-bar-overrides';
import { useTrackNavigationClick } from './use-track-navigation-click';
import { useMobileWebview } from '@src/hooks/use-mobile-webview';

type Props = {
  fallbackTitle: ReactNode;
  actions?: ReactNode;
  appBarBottom?: ReactNode;
};

export function Navigation({
  children,
  appBarBottom,
  fallbackTitle,
}: PropsWithChildren<Props>) {
  const chat = useChat();
  const { appBarState } = useTopAppBarOverrides();
  const { bottomBarState } = useBottomAppBarOverrides();
  const router = useRouter();
  const [profileOpen, setProfileOpen] = useState(false);
  const divReference = useRef<HTMLDivElement>(null);
  const dropdownReference = useRef<HTMLDivElement>(null);
  const { isAppBannerEnabled } = useFeatureFlags();
  const cookieUser = getUser();
  const { data, loading } = useQuery(NavigationDocument, {
    variables: {
      isMobile: isMobileDevice,
    },
  });
  const { profileCompletionSummary, badgesItemsCount } = data || {};
  const user = maybeUser(data?.viewer);
  const isMobileBrowser = useIsMobile(true);
  const mobileWebView = useMobileWebview();
  const isProfileComplete =
    profileCompletionSummary?.meta?.percentCompleted === 100;
  const nurseProfile = user?.nurseProfile;
  const credentialsCount = badgesItemsCount?.credentialsCount || 0;
  const favoritesCount = badgesItemsCount?.favoritesCount || 0;
  const matchesCount = badgesItemsCount?.matchesCount || 0;
  const completionItemsCount = badgesItemsCount?.completionItemsCount || 0;

  useEffect(() => {
    if (
      !mobileWebView.isPresent &&
      isMobileBrowser &&
      isAppBannerEnabled &&
      isProfileComplete
    ) {
      const script = document.createElement('script');
      script.src = '/static/smart-banner.js';
      script.id = 'smartbanner-script';
      script.async = true;
      document.body.appendChild(script);

      // Cleanup function
      return () => {
        const existingScript = document.getElementById('smartbanner-script');
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
      };
    }
  }, [
    isAppBannerEnabled,
    isMobileBrowser,
    mobileWebView.isPresent,
    isProfileComplete,
  ]);

  const [isSideMenuOpen, setNavSideMenuOpen] = useState(false);

  function openChat() {
    analytics.track(USER_VIEWED_CHAT_PANEL, { source: 'Navigation' });
    chat.open();
  }

  useEffect(() => setProfileOpen(false), [router.asPath]);

  const { aaNewHomePage } = useFeatureFlags();

  const hasBeenPlaced = nurseProfile?.hasBeenPlaced ?? false;
  const clinicianNotNew = nurseProfile?.state !== UserHomeState.New;

  const hideNavActions = isMobileBrowser && appBarState.leading;
  const favoritesHref = '/matches/favorites';

  const trackNavigation = useTrackNavigationClick();

  const navLinks = {
    home: {
      label: 'Home',
      href: '/home',
      icon: ['fal', 'house'],
      activeIcon: 'house',
      visible: true,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HOME_LINK),
    },
    profile: {
      label: 'Profile',
      href: '/profile',
      icon: ['fal', 'user-doctor'],
      activeIcon: 'user-doctor',
      visible: clinicianNotNew,
      count: aaNewHomePage ? undefined : completionItemsCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_PROFILE_LINK),
    },
    matches: {
      label: 'Matches',
      href: '/matches/filtering',
      activeIcon: 'suitcase',
      icon: ['fal', 'suitcase'],
      visible: clinicianNotNew,
      count: matchesCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_MATCHES_LINK),
    },
    apply: {
      label: 'Apply',
      href: favoritesHref,
      activeIcon: 'heart',
      icon: ['fal', 'heart'],
      visible: clinicianNotNew,
      count: favoritesCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_APPLY_LINK),
    },
    chat: {
      label: 'Chat',
      activeIcon: 'comment-alt',
      icon: ['fal', 'comment-alt'],
      visible: true,
      onClick: openChat,
    },
    credentials: {
      label: 'Credentials',
      href: '/credentials',
      activeIcon: 'copy',
      icon: ['fal', 'copy'],
      visible: clinicianNotNew,
      count: credentialsCount,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_CREDENTIALS_LINK),
    },
    invite: {
      label: 'Invite',
      href: '/invite',
      activeIcon: 'gift',
      icon: ['fal', 'gift'],
      visible: clinicianNotNew,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_INVITE_LINK),
    },
    onboarding: {
      label: 'Onboarding',
      href: '/onboarding',
      activeIcon: 'tasks',
      icon: ['far', 'tasks'],
      visible: clinicianNotNew && !!nurseProfile?.isOnboarding,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_ONBOARDING_LINK),
    },
    payAndBenefits: {
      label: 'Pay & Benefits',
      href: 'https://workforcenow.adp.com/workforcenow/login.html',
      activeIcon: 'heartbeat',
      icon: ['fal', 'heartbeat'],
      visible: clinicianNotNew && hasBeenPlaced,
      external: true,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_PAY_BENEFITS_LINK
        ),
    },
    helpCenter: {
      label: 'Help Center',
      href: 'https://help.trustedhealth.com',
      activeIcon: 'question-circle',
      icon: ['fal', 'question-circle'],
      visible: true,
      external: true,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HELP_CENTER_LINK),
    },
    account: {
      label: 'Account',
      href: '/account',
      activeIcon: 'cog',
      icon: ['fal', 'cog'],
      visible: true,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_ACCOUNT_LINK),
    },
    explorer: {
      label: 'Explorer',
      href: '/explorer',
      activeIcon: 'compass',
      icon: ['fal', 'compass'],
      visible: clinicianNotNew,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_EXPLORER_LINK),
    },
    community: {
      label: 'Community',
      href: 'https://www.facebook.com/groups/thetrustedcircle',
      activeIcon: 'users',
      icon: ['fal', 'users'],
      visible: clinicianNotNew,
      onClick: () =>
        trackNavigation(SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_COMMUNITY_LINK),
    },
    mobileDevMagic: {
      label: 'Mobile Dev Magic',
      activeIcon: 'magic',
      icon: ['fal', 'magic'],
      visible: mobileWebView.isDevMagicAllowed,
      onClick: () => {
        mobileWebView.postMessage({ action: 'OPEN_DEV_MENU' });
      },
    },
  } satisfies Record<string, NavigationLinkDefinition>;

  const visibleBarLinks = (links: NavigationLinkDefinition[]) => {
    return links
      .filter(definition => definition.visible)
      .map(definition => (
        <NavigationBarLink
          key={definition.label}
          href={definition.href!}
          activeIcon={definition.activeIcon}
          icon={definition.icon}
          target={definition.external ? '_blank' : undefined}
          rel={definition.external ? 'noreferrer noopener' : undefined}
          count={definition.count}
          // eslint-disable-next-line react/jsx-handler-names
          onClick={definition.onClick}
        >
          {definition.label}
        </NavigationBarLink>
      ));
  };

  const bottomBarLinks = visibleBarLinks([
    {
      ...navLinks.home,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_HOME_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.profile,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_PROFILE_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.matches,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_MATCHES_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.apply,
      label: 'Apply',
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_APPLY_LINK,
          'bottom'
        ),
    },
    {
      ...navLinks.invite,
      onClick: () =>
        trackNavigation(
          SEGMENT_EVENTS.NAVIGATION.CLICKED_NAV_INVITE_LINK,
          'bottom'
        ),
    },
  ]);

  const renderAppBarLeading = () => {
    if (appBarState.leading) {
      return (
        <NavMainHeaderBarActions position="left">
          {appBarState.leading}
          <NavMainHeaderBarTitle onClick={() => setProfileOpen(false)}>
            {appBarState.navTitle || fallbackTitle}
          </NavMainHeaderBarTitle>
        </NavMainHeaderBarActions>
      );
    }

    return isMobileBrowser ? (
      <NavMainHeaderBarActions position="left">
        <NavMainHeaderBarAction onClick={() => setNavSideMenuOpen(true)}>
          <FontAwesomeIcon icon={['fal', 'bars']} fixedWidth />
        </NavMainHeaderBarAction>
      </NavMainHeaderBarActions>
    ) : (
      <NavMainHeaderBarTitle onClick={() => setProfileOpen(false)}>
        {appBarState.navTitle || fallbackTitle}
      </NavMainHeaderBarTitle>
    );
  };

  const referText = navLinks.invite.visible
    ? data?.saasquatchMainPageWidget?.titleText
    : undefined;

  const renderTopAppBarTop = () => (
    <>
      {cookieUser?.isAdmin && user && (
        <AdminSessionBanner
          adminName={cookieUser.adminName}
          isAdmin={cookieUser.isAdmin}
          user={user}
        />
      )}
      <NavMainHeaderBar>
        {renderAppBarLeading()}
        {!appBarState.leading && (
          <NavMainHeaderBarLogo href={navLinks.home.href}>
            <TrustedLogomarkFullBlack width="110" />
          </NavMainHeaderBarLogo>
        )}
        <NavMainHeaderBarActions>
          {!hideNavActions && (
            <>
              {!loading && (
                <>
                  {navLinks.chat.visible && (
                    // eslint-disable-next-line react/jsx-handler-names
                    <NavMainHeaderBarAction onClick={navLinks.chat.onClick}>
                      <CountBadge color="secondary" count={chat.unreadCount}>
                        <FontAwesomeIcon
                          className="fix-chat-icon"
                          icon={navLinks.chat.icon}
                          fixedWidth
                        />
                      </CountBadge>
                    </NavMainHeaderBarAction>
                  )}
                </>
              )}
              {!isMobileBrowser && (
                <NavigationTopBarUser
                  anchorRef={divReference}
                  open={profileOpen}
                  user={user}
                  onClick={() => setProfileOpen(!profileOpen)}
                  clinicianNotNew={clinicianNotNew}
                />
              )}
            </>
          )}
        </NavMainHeaderBarActions>

        {profileCompletionSummary && user && (
          <NavigationProfileDropdown
            user={user}
            open={profileOpen}
            anchorRef={divReference}
            reference={dropdownReference}
            onClose={() => setProfileOpen(false)}
            clinicianNotNew={clinicianNotNew}
            referText={referText}
          />
        )}
        {appBarState.actions}
      </NavMainHeaderBar>
    </>
  );

  const renderTopAppBarBottom = () => {
    const effectiveBottomContent = appBarState.bottomContent || appBarBottom;
    const effectiveSystemMessages = data?.systemMessages
      ?.filter(nonNullValues)
      ?.map(message => <SystemMessage key={message.id} body={message.body} />);

    if (!effectiveSystemMessages?.length && !effectiveBottomContent) {
      return;
    }

    return (
      <>
        {effectiveSystemMessages}
        {effectiveBottomContent}
      </>
    );
  };

  const renderSideBar = () => {
    if (!user) {
      return;
    }

    return isMobileBrowser ? (
      <>
        <NavigationSideMenu
          user={user}
          open={isSideMenuOpen}
          onClose={() => setNavSideMenuOpen(false)}
          referText={referText}
          links={[
            navLinks.home,
            navLinks.profile,
            navLinks.matches,
            navLinks.apply,
            navLinks.credentials,
            navLinks.onboarding,
            navLinks.payAndBenefits,
          ].filter(definition => definition.visible)}
          extraLinks={[
            navLinks.account,
            navLinks.explorer,
            navLinks.community,
            navLinks.helpCenter,
            navLinks.mobileDevMagic,
          ].filter(definition => definition.visible)}
        />
      </>
    ) : (
      <NavSidebar>
        <NavSidebarCard>
          <NavSidebarCardHeader>
            <NavSidebarLogo href={navLinks.home.href}>
              <TrustedLogomark />
            </NavSidebarLogo>
          </NavSidebarCardHeader>
          <Scroll>
            <ScrollView>
              <NavSidebarCardMenu>
                {visibleBarLinks([
                  navLinks.home,
                  navLinks.profile,
                  navLinks.matches,
                  navLinks.apply,
                  navLinks.credentials,
                  navLinks.onboarding,
                  navLinks.payAndBenefits,
                  navLinks.invite,
                ])}
              </NavSidebarCardMenu>
            </ScrollView>
          </Scroll>
        </NavSidebarCard>
      </NavSidebar>
    );
  };

  return (
    <>
      <Nav>
        {renderSideBar()}
        <div className="main-content">
          <div className="container-for-sticky-top-app-bar">
            <NavMain>
              <NavMainHeader>
                {renderTopAppBarTop()}
                {renderTopAppBarBottom()}
              </NavMainHeader>
              {/* We have public/smart-banner.js script that looks for smart-banner className/id to add app banner. */}
              <div className="smart-banner" id="smart-banner"></div>
              <NavMainContent>{children}</NavMainContent>
            </NavMain>
          </div>
          {bottomBarLinks.length > 1 && !bottomBarState.hideBottom && (
            <NavigationBottomBar>{bottomBarLinks}</NavigationBottomBar>
          )}
        </div>
        <WelcomeBackModal />
      </Nav>
      <style jsx>
        {`
          .main-content {
            display: flex;
            flex: 1;
            flex-direction: column;
          }

          // chat icon doesn't align properly with the other icon
          .main-content :global(.fix-chat-icon) {
            margin-top: 2px;
            height: calc(1.25rem - 1px);
          }

          .container-for-sticky-top-app-bar {
            display: flex;
            overflow: auto;
            flex: 1;
          }
        `}
      </style>
    </>
  );
}
