import type { ReactNode } from 'react';
import { BaseError } from '@src/components/error/base-error';
import { Button } from '@src/ui';
import { useRouter } from 'next/router';

type Props = {
  statusCode?: number;
  actions?: ReactNode;
};

export const UnexpectedError = ({ statusCode, ...props }: Props) => (
  <BaseError
    image="/static/error/system-error.png"
    title="Sorry, it’s not you. It’s us!"
    statusCode={statusCode || 500}
    details={
      <>
        <p>Uh-oh! We encountered an issue.</p>
        <p>
          Our team is on it. Please check back later. If the error persists,{' '}
          <a href="mailto:hello@trustedhealth.com?subject=Hey%20Trusted%20Health!">
            <strong>contact us</strong>
          </a>{' '}
          for support.
        </p>
      </>
    }
    actions={<UnexpectedErrorActions />}
    {...props}
  />
);

const UnexpectedErrorActions = () => {
  const router = useRouter();
  return (
    <div className="UnexpectedErrorActions">
      <Button
        color="primary"
        shape="rounded"
        onClick={() => router.push('/home')}
      >
        Home
      </Button>
      <style jsx>{`
        .UnexpectedErrorActions {
          display: flex;
        }
      `}</style>
    </div>
  );
};
