import { notifyError } from '@src/lib/error-reporter';
import * as sunshineService from '@src/lib/sunshine';
import * as colors from '@src/support/colors';
import { Button, FontAwesomeIcon, ResponsiveDrawer } from '@src/ui';
import { Formik } from 'formik';

import { useState } from 'react';
import * as Yup from 'yup';
import { DecisionTree } from './decision-tree';
import { ChatPrompt } from './decision-tree/chat-prompt';
import { MessagesInbox } from './messages-inbox';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  user: { id: string; sunshineToken?: string | null };
  openSunshine: () => void;
  initialTopic?: string;
  initialSubtopic?: string;
};

/**
 * @deprecated Direct use of `ChatPanel` is discouraged.
 * Use `useChat()` instead.
 */
export function ChatPanel({
  isOpen,
  onClose,
  user,
  openSunshine,
  initialTopic = '',
  initialSubtopic = '',
}: Props) {
  const promptedByModal = initialTopic !== '';
  const [newConversation, setNewConversation] = useState(promptedByModal);
  const [initialMessage, setInitialMessage] = useState<string>();
  const [hasConversations, setHasConversations] = useState(true);
  const [loading, setLoading] = useState(!promptedByModal);
  const [error, setError] = useState(false);
  const [topic, setTopic] = useState(initialTopic);
  const [subtopic, setSubtopic] = useState(initialSubtopic);
  const [articleOpen, setArticleOpen] = useState(false);
  const [articleId, setArticleId] = useState('');
  const [articleName, setArticleName] = useState('');

  const backToConversationList = () => {
    closePanel();
    if (hasConversations) {
      openSunshine();
    }
  };

  const closePanel = () => {
    setNewConversation(promptedByModal);
    setInitialMessage(undefined);
    onClose();
    setLoading(!promptedByModal);
  };

  const onError = () => {
    notifyError('Failed to load Sunshine Conversations chat', {
      context: 'Sunshine Conversations Chat',
    });
    setError(true);
  };

  return (
    <ResponsiveDrawer
      open={isOpen}
      anchor="right"
      articleOpen={articleOpen}
      onClose={closePanel}
    >
      <>
        {loading && (
          <div className="overlay">
            <FontAwesomeIcon icon={['fal', 'spinner']} spin size="3x" />
          </div>
        )}
        {error && (
          <div className="overlay">
            <div>
              <FontAwesomeIcon
                icon={['fal', 'exclamation-circle']}
                size="3x"
                color={colors.semanticError}
              />
            </div>
            <div className="overlay-error-message">
              <div>Uh oh!</div>
              <div>We&apos;re sorry but chat experienced an issue.</div>
              <br />
              <div>
                Please refresh by clicking the button below. If this issue
                persists, you can reach us at hello@trustedhealth.com
              </div>
            </div>
            <br />
            <Button
              color="primary"
              type="button"
              shape="rounded"
              onClick={() => {
                setLoading(true);
                setError(false);
                setHasConversations(true);
                backToConversationList();
              }}
            >
              Refresh
            </Button>
          </div>
        )}
        {newConversation ? (
          promptedByModal ? (
            <Formik
              validationSchema={Yup.object().shape({
                question: Yup.string()
                  .matches(/[^\s]/, 'Message must contain a valid character')
                  .required(),
              })}
              enableReinitialize
              initialValues={{
                question: '',
              }}
              onSubmit={values => {
                const messageText = values.question;

                sunshineService.destroy();
                sunshineService.init(user);
                window.TrustedHealthSunshine!.on!('widget:closed', () => {
                  closePanel();
                  sunshineService.destroy();
                });
                setInitialMessage(messageText);
                setNewConversation(false);
              }}
            >
              {({ handleSubmit }) => (
                <ChatPrompt
                  topic={topic}
                  subtopic={subtopic}
                  handleSubmit={handleSubmit}
                />
              )}
            </Formik>
          ) : (
            <DecisionTree
              backToConversationList={backToConversationList}
              setLoading={setLoading}
              topic={topic}
              setTopic={setTopic}
              subtopic={subtopic}
              setSubtopic={setSubtopic}
              onSubmit={values => {
                const messageText = values.question;

                sunshineService.destroy();
                sunshineService.init(user);
                window.TrustedHealthSunshine!.on!('widget:closed', () => {
                  closePanel();
                  sunshineService.destroy();
                });
                setInitialMessage(messageText);
                setNewConversation(false);
              }}
              closePanel={closePanel}
              setArticleOpen={setArticleOpen}
              setArticleId={setArticleId}
              setArticleName={setArticleName}
              articleId={articleId}
              articleName={articleName}
            />
          )
        ) : (
          <MessagesInbox
            onError={onError}
            onClickNewConversation={() => setNewConversation(true)}
            setLoading={setLoading}
            initialMessage={initialMessage}
            topic={topic}
            subtopic={subtopic}
            articleId={articleId}
            articleName={articleName}
            setInitialState={() => {
              setLoading(true);
              setHasConversations(false);
              setNewConversation(true);
            }}
          />
        )}
      </>
      <style jsx>{`
        .overlay {
          position: absolute;
          top: 3.75em;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 1;
          padding: 1em;
          background-color: ${colors.white};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: ${colors.neutralHighest1};
          z-index: 9999;
        }

        .overlay {
          height: calc(100% - 3.75em);
        }

        .overlay-error-message {
          margin: 10px 0;
          color: ${colors.semanticError};
          text-align: center;
        }
      `}</style>
    </ResponsiveDrawer>
  );
}
