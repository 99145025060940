import {
  type HomeUserFragment,
  type JobPlacementScheduleSummaryFragment,
  type RequestReviewFragment,
  RequestReviewStatusEnum,
} from '@generated/graphql';
import { useFeatureFlags } from '@src/contexts/feature-flags';

export enum UserHomeState {
  New = 'new',
  IncompleteProfile = 'incomplete_profile',
  CompleteProfile = 'complete_profile',
  ActivatedProfile = 'activated_profile',
  Onboarding = 'onboarding',
  Working = 'working',
  WorkingAndOnboarding = 'working_and_onboarding',
}

const isProfileCompleted = (percentCompleted: number) =>
  percentCompleted === 100;

export const useUserState = (
  nurseProfile: HomeUserFragment['nurseProfile'] | null | undefined,
  currentJobPlacement:
    | readonly JobPlacementScheduleSummaryFragment[]
    | null
    | undefined,
  profilePercentComplete: number,
  reviewRequest: RequestReviewFragment | null | undefined
): UserHomeState | null => {
  const { splitSignUpAndApprovalFlow } = useFeatureFlags();

  if (!nurseProfile) {
    return null;
  }

  if (splitSignUpAndApprovalFlow && nurseProfile.state === 'new') {
    return UserHomeState.New;
  }

  if (nurseProfile?.isOnboarding) {
    return currentJobPlacement?.length
      ? UserHomeState.WorkingAndOnboarding
      : UserHomeState.Onboarding;
  }

  if (currentJobPlacement?.length) {
    return UserHomeState.Working;
  }

  if (isProfileCompleted(profilePercentComplete)) {
    return reviewRequest?.status === RequestReviewStatusEnum.Hidden
      ? UserHomeState.ActivatedProfile
      : UserHomeState.CompleteProfile;
  }

  return UserHomeState.IncompleteProfile;
};
