import {
  type ReactElement,
  createContext,
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import { LoadScriptNext, type LoadScriptProps } from '@react-google-maps/api';
import getConfig from 'next/config';

type Service = google.maps.places.AutocompleteService;

const { GOOGLE_MAPS_API_KEY } = getConfig().publicRuntimeConfig;

const Context = createContext<
  readonly [service: Service | undefined, loading: boolean]
>([undefined, true]);

const libraries: LoadScriptProps['libraries'] = ['places'];

export const GoogleMapsScriptProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState<Service | undefined>();

  const mapsLoaded = useCallback(() => {
    // window.google isn't set until the script loads
    if (!service) {
      setService(new window.google.maps.places.AutocompleteService());
      setLoading(false);
    }
  }, [service]);

  const value = useMemo(() => [service, loading] as const, [loading, service]);

  return (
    <Context.Provider value={value}>
      <LoadScriptNext
        libraries={libraries}
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        loadingElement={<div style={{ height: '100%' }} />}
        onLoad={mapsLoaded}
      >
        {children}
      </LoadScriptNext>
    </Context.Provider>
  );
};

export const useGoogleMapsScript = () => useContext(Context);
