import type { PropsWithChildren } from 'react';

import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';

export const NAV_MAIN_HEADER_BAR_HEIGHT = '3.5rem';

export const NavMainHeaderBar = (props: PropsWithChildren<{}>) => (
  <div className="NavMainHeaderBar">
    {props.children}
    <style jsx>
      {`
        .NavMainHeaderBar {
          background-color: ${colors.white};
          display: grid;
          grid-template-columns: auto 1fr auto;
          height: ${NAV_MAIN_HEADER_BAR_HEIGHT};
          padding: 0 1rem;
          z-index: 1; // for bottom shadow to appear
          box-shadow: ${rgba(colors.black, 0.05)} 0 0.125rem 0 0;
        }
      `}
    </style>
  </div>
);
