import * as colors from '@src/support/colors';
import type { ChangeEventHandler } from 'react';

type Props = {
  onToggle: ChangeEventHandler<HTMLInputElement>;
  toggle: boolean;
};

export function ToggleSwitch({ toggle, onToggle }: Props) {
  return (
    <label className="ToggleSwitch">
      <input
        type="checkbox"
        checked={toggle}
        onChange={onToggle}
        className="ToggleSwitchCheckbox"
      />
      <span className="ToggleSwitchSwitch" />
      <style jsx>{`
        .ToggleSwitch {
          position: relative;
          display: inline-block;
          width: 1.5625em;
          height: 0.875em;
        }

        .ToggleSwitch input[type='checkbox'] {
          display: none;
        }

        .ToggleSwitchSwitch {
          position: absolute;
          cursor: pointer;
          background-color: ${colors.neutralHighest};
          border-radius: 1.25em;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0.1875em;
          transition: background-color 0.2s ease;
        }

        .ToggleSwitchSwitch::before {
          position: absolute;
          content: '';
          top: -0.1875em;
          right: 0.8125em;
          width: 1.25em;
          height: 1.25em;
          background-color: ${colors.white};
          border-radius: 50%;
          transition: transform 0.3s ease;
          box-shadow: 0em 0.0625em 0.3125em rgba(18, 81, 91, 0.25);
        }

        .ToggleSwitch
          input[type='checkbox']:checked
          + .ToggleSwitchSwitch::before {
          transform: translateX(25px);
          background-color: ${colors.semanticSuccess};
        }

        .ToggleSwitch input[type='checkbox']:checked + .ToggleSwitchSwitch {
          background-color: ${colors.semanticSuccessLower};
        }
      `}</style>
    </label>
  );
}
