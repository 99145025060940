import { useContext, type ComponentProps, type ReactNode } from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Field } from 'formik';
import { FormikCheckboxFieldContext } from './formik-checkbox-field-context';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';

function castValue(value: any, castValue: any) {
  return castValue === 'boolean' ? value.toString() : value;
}

type Props = {
  castValue?: string;
  CheckboxProps?: ComponentProps<typeof Checkbox>;
  FormControlLabelProps?: Omit<
    ComponentProps<typeof FormControlLabel>,
    'control' | 'label'
  >;
  label: string | ReactNode;
  value?: string | boolean;
  innerRef?: any;
  className?: ClassValue;
};

export function FormikCheckboxFieldOption(props: Props) {
  const checkboxField = useContext(FormikCheckboxFieldContext);
  const CheckboxProps = Object.assign(
    { color: 'primary' },
    props.CheckboxProps
  );

  return (
    <Field
      innerRef={props.innerRef}
      name={checkboxField.name}
      type="checkbox"
      value={props.value}
    >
      {({ field }: any) => {
        return (
          <FormControlLabel
            {...props.FormControlLabelProps}
            control={
              <Checkbox
                {...CheckboxProps}
                {...field}
                value={castValue(props.value ?? field.value, props.castValue)}
                className={clsx(props.className)}
                onChange={(e, checked) => {
                  field.onChange(e, checked);
                  CheckboxProps.onChange && CheckboxProps.onChange(e, checked);
                }}
              />
            }
            label={props.label}
          />
        );
      }}
    </Field>
  );
}
