import { type PropsWithChildren } from 'react';
import { TrustedHead } from '@src/components/trusted-head';
import { Navigation } from '@src/components/navigation';
import {
  type TopAppBarBottomTabProps,
  TopAppBarBottomTabs,
} from '@src/components/top-app-bar';
import { SnackbarContainer } from '@src/components/snackbar-container';
import { ChatProvider } from '@src/contexts/chat-provider';
import {
  TopAppBarOverridesProvider,
  useTopAppBarOverrides,
} from '@src/contexts/top-app-bar-overrides';
import { BottomAppBarOverridesProvider } from '@src/contexts/bottom-app-bar-overrides';

type Props = {
  topAppBarBottomTabs?: TopAppBarBottomTabProps;
  headTitle?: string;
  navTitle?: string;
};

const AppBarTrustedHeaders = ({ fallbackTitle }: { fallbackTitle: string }) => {
  const { appBarState } = useTopAppBarOverrides();

  return (
    <TrustedHead
      title={appBarState.headTitle || fallbackTitle}
      description={appBarState.headDescription}
    />
  );
};

export function NavigationProvider({
  children,
  topAppBarBottomTabs,
  headTitle = '',
  navTitle = '',
}: PropsWithChildren<Props>) {
  return (
    <>
      <TopAppBarOverridesProvider>
        <BottomAppBarOverridesProvider>
          <AppBarTrustedHeaders fallbackTitle={headTitle} />
          <ChatProvider>
            <Navigation
              fallbackTitle={navTitle}
              appBarBottom={
                topAppBarBottomTabs ? (
                  <TopAppBarBottomTabs {...topAppBarBottomTabs} />
                ) : null
              }
            >
              {children}
            </Navigation>
          </ChatProvider>
          <SnackbarContainer />
        </BottomAppBarOverridesProvider>
      </TopAppBarOverridesProvider>
    </>
  );
}
