import {
  type SetStateAction,
  type Dispatch,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Box, Grid } from '@material-ui/core';
import { IconBox } from './icon-box';
import { OtherTopics } from './other-topics';
import { analytics } from '@src/lib/analytics';
import { IntercomCollectionsDocument } from '@generated/graphql';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { getIconForTopicId, removeAmpersand } from '@src/utils/help-center';
import { notifyErrorCallback } from '@src/lib/error-reporter';
import { isUser } from '@src/utils/user-utils';
import { HelpcenterCard } from './helpcenter-card';
import { SEGMENT_EVENTS } from '@src/constants';

type Props = {
  setHeaderTitle: (title: string) => void;
  setLoading: (loading: boolean) => void;
  onClick: (topic: string) => void;
  setTopicIcon: Dispatch<SetStateAction<string>>;
  setCollectionId: Dispatch<SetStateAction<string>>;
};

export function TopicSelection({
  setHeaderTitle,
  setLoading,
  onClick,
  setTopicIcon,
  setCollectionId,
}: Props) {
  const [nurseStatus, setNurseStatus] = useState<string | null | undefined>('');

  const { data, loading } = useQuery(IntercomCollectionsDocument, {
    variables: { isDuckling: true },
    onError: notifyErrorCallback(
      'Topic Selection - Intercom duckling collection list'
    ),
    fetchPolicy: 'cache-and-network',
  });

  const { data: duckData } = useQuery(IntercomCollectionsDocument, {
    variables: { isDuckling: false },
    skip: nurseStatus === 'duckling',
    onError: notifyErrorCallback(
      'Topic Selection - Intercom duck collection list'
    ),
    fetchPolicy: 'cache-and-network',
  });

  const nurseProfile = isUser(data?.viewer)
    ? data.viewer?.nurseProfile
    : undefined;

  useEffect(() => {
    setNurseStatus(nurseProfile?.statusForHelpArticles);
    setLoading(loading);
  }, [
    loading,
    nurseProfile?.statusForHelpArticles,
    setLoading,
    setNurseStatus,
  ]);

  const topics = useMemo(() => {
    return (duckData?.collections || [])?.map(item => ({
      id: item.id,
      name: removeAmpersand(item.name),
      icon: getIconForTopicId(item.id),
    }));
  }, [duckData?.collections]);

  const otherTopics = useMemo(() => {
    return (data?.collections || [])?.map(item => ({
      id: item.id,
      name: removeAmpersand(item.name),
      icon: getIconForTopicId(item.id),
    }));
  }, [data?.collections]);

  useEffect(() => {
    setHeaderTitle('Help Topics');
  }, [setHeaderTitle]);

  return (
    <Box p={1}>
      <HelpcenterCard>
        {(nurseStatus !== 'duckling' ? topics : otherTopics).map((item, i) => (
          <Grid
            xs={12}
            key={i}
            onClick={() => {
              analytics.track(SEGMENT_EVENTS.CHAT_PANEL.TOPIC_SELECTED, {
                source: 'Select Topic Page Web',
                topic: item.name,
              });
              onClick(item.name);
              setTopicIcon(item.icon);
              setCollectionId(item.id);
            }}
          >
            <IconBox icon={item.icon} text={item.name} />
          </Grid>
        ))}
      </HelpcenterCard>
      {nurseStatus !== 'duckling' && (
        <HelpcenterCard title="Other topics">
          {otherTopics.map((item, i) => (
            <OtherTopics
              key={i}
              topic={item.name}
              onClick={() => {
                onClick(item.name);
                setTopicIcon(item.icon);
                setCollectionId(item.id);
              }}
            />
          ))}
        </HelpcenterCard>
      )}
    </Box>
  );
}
