import { useState, useEffect } from 'react';
import { Snackbar } from '@src/ui';
import { useReactiveVar } from '@apollo/client';
import { type SnackbarMessage, snackbarsVar } from '@src/apollo/local';

export function SnackbarContainer() {
  const snackbars = useReactiveVar(snackbarsVar);
  const [open, setOpen] = useState(true);
  const [snackbar, setSnackbar] = useState<SnackbarMessage | undefined>(
    undefined
  );

  useEffect(() => {
    if (snackbar === undefined && snackbars.length > 0) {
      setSnackbar(snackbars[0]);
      setOpen(true);
    }
  }, [snackbars, snackbar]);

  if (snackbars.length === 0) return null;
  if (!snackbar?.id) return null;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      key={snackbar.id}
      message={snackbar.message}
      onExited={() => {
        snackbarsVar(snackbars.filter(s => s.id !== snackbar.id));
        setSnackbar(undefined);
      }}
      onClose={() => setOpen(false)}
      open={open}
      autoHideDuration={snackbar.autoHideDuration}
      color={snackbar.color}
      close={snackbar.close}
    />
  );
}
