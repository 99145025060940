type Props = {
  displayName: string;
};

export function WelcomeBackModalHeader({ displayName }: Props) {
  return (
    <div>
      <div className="WelcomeBackModalTitle">
        <p>Welcome back, {displayName}!</p>
        <p>Just checking in — is this information still accurate?</p>
      </div>
      <p className="WelcomeBackModalSubtitle">
        Personalize your Trusted experience by updating your preferences.
      </p>
      <style jsx>{`
        .WelcomeBackModalTitle {
          font-size: 1.25rem;
          font-weight: bold;
          line-height: 1.35em;
        }

        .WelcomeBackModalSubtitle {
          font-size: 1rem;
          line-height: 1.375em;
          margin-top: 1.25em;
          margin-bottom: 1.25em;
        }
      `}</style>
    </div>
  );
}
