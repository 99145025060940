import type { PropsWithChildren } from 'react';

export function WelcomeBackModalDropdownContainer({
  children,
}: PropsWithChildren<{}>) {
  return (
    <div className="WelcomeBackModalDropdownContainer">
      {children}
      <style jsx>{`
        .WelcomeBackModalDropdownContainer {
          display: flex;
          justify-content: space-between;
          font-size: 1em;
          margin-bottom: 0.625em;
          margin-top: 0.625em;
        }
      `}</style>
    </div>
  );
}
