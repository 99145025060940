import type { MouseEventHandler, PropsWithChildren } from 'react';

import * as colors from '@src/support/colors';
import { forwardRef } from 'react';

type Props = {
  onClick?: MouseEventHandler;
  href?: string;
};

export const NavMainHeaderBarAction = forwardRef<any, PropsWithChildren<Props>>(
  ({ children, href, onClick }, ref) => {
    const Component = href ? 'a' : 'button';

    return (
      <Component
        className="NavMainHeaderBarAction"
        data-testid="nav-main-header-bar-action"
        ref={ref}
        href={href}
        onClick={onClick}
      >
        {children}
        <style jsx>{`
          .NavMainHeaderBarAction {
            -webkit-tap-highlight-color: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            appearance: none;
            background-color: inherit;
            border-width: 0;
            font-size: 1.25rem;
            width: 2.25rem;
            height: 2.25rem;
            border-radius: 0.25rem;
            color: ${colors.neutralHighest};

            // Reset for safari and chrome user agent styles for button
            padding: 1px 6px;
          }

          .NavMainHeaderBarAction:focus,
          .NavMainHeaderBarAction:hover {
            background-color: ${colors.neutralLower};
          }

          .NavMainHeaderBarAction:active {
            background-color: ${colors.accent01Lower};
          }
        `}</style>
      </Component>
    );
  }
);

NavMainHeaderBarAction.displayName = 'NavMainHeaderBarAction';
