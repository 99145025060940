import { useState, useEffect, useCallback, useRef } from 'react';
import { type AutoCompleteProps, AutoComplete } from '@src/ui';
import { debounce } from 'lodash';
import { useGoogleMapsScript } from '@src/contexts/google-maps-script-provider';

type Prediction = google.maps.places.AutocompletePrediction;
type OwnProps = {
  componentRestrictions?: google.maps.places.ComponentRestrictions;
  onChange?: (item: Prediction) => void;
  types?: string[];
  textFieldSize?: 'small' | 'medium' | undefined;
  textFieldVariant?: 'filled' | 'outlined' | 'standard' | undefined;
  onReady?: () => void;
  hideCountryLabel?: boolean;
};

type Props = OwnProps &
  Omit<
    AutoCompleteProps<Prediction>,
    'items' | 'itemToValue' | 'itemToKey' | 'onChange'
  >;

export function GooglePlacesAutoComplete({
  hideCountryLabel,
  componentRestrictions = { country: 'us' },
  onChange,
  onReady,
  onInputValueChange,
  textFieldVariant = 'standard',
  textFieldSize,
  types = ['(regions)'],
  ...props
}: Props) {
  const [autocompleteService, autocompleteLoading] = useGoogleMapsScript();
  const [searchString, setSearchString] = useState('');
  const [items, setItems] = useState<readonly Prediction[]>([]);

  const handlePredictionsChange = useCallback(
    (
      predictions: Prediction[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        console.error('error', status);
      } else {
        const excludeCountryLabel = ({ description, ...rest }: Prediction) => {
          const normalizedDescription = description.replace(', USA', '');
          return { description: normalizedDescription, ...rest };
        };

        const newItems = hideCountryLabel
          ? predictions?.map(excludeCountryLabel)
          : predictions;
        setItems(newItems ?? []);
      }
    },
    [hideCountryLabel]
  );

  const searchStringUpdate = useCallback(
    (value: string) => setSearchString(value),
    [setSearchString]
  );

  // useCallback and useMemo aren't guaranteed to always keep the first instance around,
  // using useRef because we don't want to ever lose our reference to the debounced copy
  // of this method
  const debouncedSearchStringUpdate = useRef(
    debounce(searchStringUpdate, 300, {
      leading: true,
      trailing: true,
    })
  );

  const handleInputValueChange = (value: string) => {
    debouncedSearchStringUpdate.current(value);
    onInputValueChange?.(value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectionChange = (_event: any, item?: Prediction) => {
    if (item && onChange) {
      onChange(item);
    }
  };

  useEffect(() => {
    if (searchString.trim() !== '' && autocompleteService) {
      autocompleteService.getPlacePredictions(
        {
          input: searchString,
          types: types,
          componentRestrictions: componentRestrictions,
        },
        handlePredictionsChange
      );
    } else {
      setItems([]);
    }
    // Disabling linting on this because `types` and `componentRestrictions`
    // might change every render and we can't enforce that parent components
    // will give us stable objects
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, autocompleteService, handlePredictionsChange]);

  useEffect(() => {
    const debounced = debouncedSearchStringUpdate.current;
    return () => {
      debounced.cancel();
    };
  }, [debouncedSearchStringUpdate]);

  return (
    <AutoComplete
      {...props}
      disabled={autocompleteLoading}
      loading={autocompleteLoading}
      dataCy="location-field"
      textFieldSize={textFieldSize}
      textFieldVariant={textFieldVariant}
      inputProps={{
        autoComplete: 'off',
        'aria-label': 'Search',
        ...props.inputProps,
      }}
      items={items}
      itemToValue={item => (item ? item.description : '')}
      itemToKey={item => (item ? item.place_id : '')}
      onChange={handleSelectionChange}
      onInputValueChange={handleInputValueChange}
    />
  );
}
