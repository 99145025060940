import { makeStyles } from '@material-ui/core/styles';
import clsx, { type ClassValue } from 'clsx';
import * as colors from '@src/support/colors';
import type { PropsWithChildren } from 'react';
import { rgba } from '@src/support/rgba';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    height: '3.75em',
    minHeight: '3.75em',
    boxShadow: `${rgba(colors.black, 0.15)} 0px 0.0625em 0.1875em 0`,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
  },
  sticky: {
    position: 'sticky',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
});

interface OwnProps {
  sticky?: boolean;
  className?: ClassValue;
}

type Props = PropsWithChildren<OwnProps> & JSX.IntrinsicElements['header'];

export const PanelHeader = ({
  children,
  className,
  sticky,
  ...props
}: Props) => {
  const classes = useStyles();

  return (
    <header
      className={clsx(classes.root, { [classes.sticky]: sticky }, className)}
      {...props}
    >
      {children}
    </header>
  );
};
