import type { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx, { type ClassValue } from 'clsx';

import { useFeatureFlags } from '@src/contexts/feature-flags';

const useStyles = makeStyles({
  root: {
    margin: '0 0.3125em',
    alignSelf: 'center',
    fontWeight: 500,
    width: '100%',
  },
});

interface OwnProps {
  className?: ClassValue;
}

type Props = PropsWithChildren<OwnProps> & JSX.IntrinsicElements['h4'];

export const PanelHeaderTitle = ({ className, children, ...props }: Props) => {
  // testing some visual changes during Relias SSO experiment
  const { isReliasSSOEnabled } = useFeatureFlags();

  // Needs to be invoked non-conditionally to avoid React / lint error:
  // "React Hooks must be called in the exact same order in every component render"
  const useStyleClasses = useStyles();

  const classes = isReliasSSOEnabled
    ? 'mt-0 mb-0 ml-1 mr-2 full-width self-center font-normal text-lg'
    : useStyleClasses.root;

  return (
    <h4 className={clsx(classes, className)} {...props}>
      {children}
    </h4>
  );
};
