import { curry } from 'lodash';

export const pluralize = curry<string, string, number, string>(
  (plural, singular, count) => (count === 1 ? singular : plural)
);

export const pluralizeDay = pluralize('days', 'day');

export const toTitleCase = (word: string) => {
  if (word.length === 0) return word;
  return word[0].toUpperCase() + word.slice(1);
};

export function titleCaseToKebabCase(aString: string) {
  return aString.split(' ').join('-').toLowerCase();
}

export function capitalizeEachWord(str: string) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}

export const removePeriod = (sentence: string): string =>
  sentence.replace(/\.$/, '');

// cities with more than two words in it have the second word lowercased
// some cities have extra info at the end, starting with a comma (,)
export function sanitizeBusinessCity(city?: string) {
  return city ? capitalizeEachWord(city.split(',')[0]) : '';
}

/**
 * @param broadPrefLocations An array of location preferences
 * @param locationStrings A list of specific locations
 * @returns A placeholder
 *
 * I.E. if a user prefers compact_states and Oakland, CA as a specific_location, returns 'Compact + 1 location'
 */
export function getLocationPlaceholder(
  broadPrefLocations: readonly string[],
  locationStrings: string[]
) {
  let placeholder = '';

  if (broadPrefLocations.includes('compact_states')) {
    placeholder = 'Compact';
  }
  if (
    broadPrefLocations.includes('specific_locations') &&
    locationStrings?.length
  ) {
    if (placeholder !== '') {
      placeholder += ' + ';
    }
    placeholder += `${locationStrings?.length} ${pluralize(
      'locations',
      'location'
    )(locationStrings?.length)}`;
  }
  if (placeholder === '') {
    placeholder = 'Anywhere';
  }

  return placeholder;
}
