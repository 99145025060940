import { createContext, type ReactNode, useContext, useMemo } from 'react';

import { FeatureFlagsDocument } from '@generated/graphql';
import { useQuery } from '@src/hooks/use-graphql-hooks';
import { LoadingOverlay } from '@src/components/loading-overlay';

// exported only for tests
export const FLAG_DEFAULTS = {
  isReliasSSOEnabled: false,
  isAddOtherFacilityEnabled: false,
  isDoubleOptInFieldEnabled: false,
  isAppBannerEnabled: false,
  nursysLicenseVerificationMultipleStates: false,
  showScheduleCallAdjustments: false,
  showImprovedApplicationFlowIncrementBannerCount: false,
  showImprovedApplicationFlowIncrement: false,
  splitSignUpAndApprovalFlow: false,
  aaNewHomePage: false,
  aaNewProfile: false,
};

type FeatureFlags = typeof FLAG_DEFAULTS;

// exported only for tests
export const FeatureFlagsContext = createContext(FLAG_DEFAULTS);

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const query = useQuery(FeatureFlagsDocument);

  const effectiveFlags = useMemo(
    () => ({
      ...FLAG_DEFAULTS,
      ...((query.data?.featureFlags || {}) as FeatureFlags),
    }),
    [query.data]
  );

  if (query.loading && !query.data) {
    return <LoadingOverlay />;
  }

  return (
    <FeatureFlagsContext.Provider value={effectiveFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
