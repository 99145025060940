import { notifyError } from '@src/lib/error-reporter';
import type { IncomingMessage, OutgoingHttpHeaders } from 'http';
import type { NextApiRequest } from 'next';
import {
  CookieName,
  getAmplitudeSessionIdFromCookieString,
  getCookieFromCookieString,
  getUserFromCookieString,
} from '@src/utils/cookies';

import type { GraphQLError } from 'graphql';
import type {
  DefaultContext,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import getConfig from 'next/config';
import { v4 } from 'uuid';
import { omit } from 'lodash';

const { NODE_ENV } = getConfig().publicRuntimeConfig;

const isServer = typeof window === 'undefined';

const TRACE_ID_HEADER = 'X-TRACE-ID';

const shouldReportError = (
  message: string,
  path?: readonly (string | number)[],
  extensions?: Record<string, any>
) => {
  const checks = [
    // This just means the user's session expired or they need to log in
    extensions?.code === 'UNAUTHENTICATED',

    // We throw exceptions for bad user input. error reporting doesn't care about those.
    extensions?.code === 'BAD_USER_INPUT' ||
      extensions?.code === 'UNEXPECTED_VALIDATION_ERROR',

    // Relias rate limits are also not useful in error reporting
    path?.[0] === 'assignReliasAssessments' &&
      message.includes('reached the request limit'),

    // Not much we can do about network connections failing. These are often mobile users with unreliable signal
    message.includes('The network connection was lost'),
  ];

  return !checks.includes(true);
};

export const handleGraphQLError = (
  errors: readonly GraphQLError[],
  query: string,
  variables: Record<string, any>,
  context: DefaultContext
) => {
  errors.forEach(({ message, locations, path, extensions }) => {
    if (NODE_ENV !== 'production') {
      console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`);
    }

    if (shouldReportError(message, path, extensions)) {
      const pathText = path ? `${path[0]}` : '';
      const errorCode = extensions?.code ? ` - code: ${extensions.code}` : '';
      const errorGroup = `GraphQL - ${pathText}${errorCode}`;
      const errorMessage = `${path ? `${path[0]}: ` : ''}${message}`;

      notifyError(errorMessage, {
        groupBy: errorGroup,
        extraSections: {
          GraphQL: {
            extensions,
            locations,
            path,
            variables,
            query: query || 'UNKNOWN',
            context: cleanupContext(context),
            trace_id: context.headers[TRACE_ID_HEADER],
          },
        },
      });
    }
  });
};

export const handleNetworkError = (
  error: Error | ServerError | ServerParseError,
  query: string,
  variables: Record<string, any>,
  context: DefaultContext
) => {
  if (NODE_ENV !== 'production') {
    console.error('[GraphQL network error]:', error);
  }

  const errorGroup = 'GraphQL - Network Error Data';

  notifyError(error, {
    groupBy: errorGroup,
    extraSections: {
      request: {
        query,
        variables,
        context: cleanupContext(context),
      },
      response: {
        error: isServerError(error) ? serializeServerError(error) : error,
        response:
          'response' in error ? serializeResponse(error.response) : null,
      },
      metadata: {
        trace: { trace_id: context.headers[TRACE_ID_HEADER] },
      },
    },
  });
};

const isServerError = (
  error: Error
): error is ServerParseError | ServerError => {
  // from @apollo/client/link (unfortunately, it's not a class, so we can't instanceof)
  return error?.name === 'ServerParseError' || error?.name === 'ServerError';
};

const serializeServerError = (error: ServerParseError | ServerError) => ({
  message: error?.message,
  name: error?.name,
  bodyText: error && 'bodyText' in error ? error.bodyText : null,
  statusCode: error?.statusCode,
});

// response is a Response object, JSON.stringify will not serialize it
const serializeResponse = (response: Response) => ({
  status: response?.status,
  statusText: response?.statusText,
  type: response?.type,
  url: response?.url,
  ok: response?.ok,
  redirected: response?.redirected,
});

// context.cache is too big and circular, and we don't really use it
const cleanupContext = (context: DefaultContext) => omit(context, 'cache');

export const isLoggedIn = (headers: OutgoingHttpHeaders) => {
  return !!headers['X-USER-TOKEN'] && !!headers['X-USER-EMAIL'];
};

export const getCookieString = (req?: {
  headers?: {
    cookie?: string;
  };
}) => (isServer ? (req?.headers?.cookie ?? '') : document.cookie);

const generateTraceId = () => v4();

const getSessionInfo = (req: any) => {
  const cookieString = getCookieString(req);

  return {
    'X-ANONYMOUS-ID':
      getCookieFromCookieString(CookieName.AjsAnonymousId, cookieString) ?? '',
    'X-AMPLITUDE-SESSION-ID':
      getAmplitudeSessionIdFromCookieString(cookieString) ?? '',
  };
};

export function getGqlHeaders(
  req?: NextApiRequest | IncomingMessage
): Record<string, string> {
  const user = getUserFromCookieString(getCookieString(req));
  const adminUuid = user?.adminUserUuid;

  if (user) {
    return {
      'X-USER-TOKEN': user.authToken,
      'X-USER-EMAIL': user.email,
      [TRACE_ID_HEADER]: generateTraceId(),
      // User was impersonated using the 'Become' feature
      ...(adminUuid ? { 'X-IMPERSONATOR-ADMIN-UUID': adminUuid } : {}),
      ...getSessionInfo(req),
    };
  }

  return {};
}

export function getRestHeaders(
  req?: NextApiRequest | IncomingMessage
): Record<string, string> {
  return {
    ...getGqlHeaders(req),
    'Content-Type': 'application/json',
  };
}

export function fetchWithRetries(
  input: RequestInfo,
  numRetries: number,
  wait = 3000,
  init?: RequestInit
) {
  if (numRetries <= 0) {
    return;
  }
  fetch(input, init)
    .then(response => {
      if (!response.ok) {
        return Promise.reject((response && response.body) || response.status);
      }
    })
    .catch(() =>
      setTimeout(() => {
        fetchWithRetries(input, numRetries - 1, wait, init);
      }, wait)
    );
}

/*
 * Expects a url (e.g. https://app.trustedhealth.com/whatever)
 * Returns the domain (e.g. trustedhealth.com)
 */
export function getHostDomainNameFromURL(url: URL) {
  const hostname = url.hostname;
  const components: string[] = hostname.split('.');
  return components.length < 3
    ? components.join('.')
    : components.slice(components.length - 2).join('.');
}
