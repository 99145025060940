import type { PropsWithChildren } from 'react';

export function WelcomeBackModalSelectorContainer({
  children,
}: PropsWithChildren<{}>) {
  return (
    <div className="WelcomeBackModalSelectorContainer">
      {children}

      <style jsx>{`
        .WelcomeBackModalSelectorContainer {
          border-radius: 0.25em;
        }
      `}</style>
    </div>
  );
}
