import type { WelcomeBackModalNursePreferencesFragment } from '@generated/graphql';
import { SEGMENT_EVENTS } from '@src/constants';
import { analytics } from '@src/lib/analytics';

export const trackUserIntentConfirmed = ({
  userId,
  updatedNursePreferences,
}: {
  userId: string;
  updatedNursePreferences: WelcomeBackModalNursePreferencesFragment;
}) => {
  if (!userId) return;

  const { startPref, locations, broadPrefLocations, earliestStartDate } =
    updatedNursePreferences;

  analytics.track(SEGMENT_EVENTS.DASHBOARD.USER_INTENT_CONFIRMED, {
    user_id: userId,
    intent: startPref,
    Date: startPref === 'specific_date' ? earliestStartDate : null,
    location_pref:
      startPref === 'not_looking'
        ? null
        : broadPrefLocations?.length === 0
          ? 'Anywhere'
          : locations?.map(loc => loc.locationString).join('; '),
  });
};

export const trackUserViewedIntentModal = ({
  userId,
  nursePreferences,
}: {
  userId: string;
  nursePreferences: WelcomeBackModalNursePreferencesFragment;
}) => {
  analytics.track(SEGMENT_EVENTS.DASHBOARD.USER_VIEWS_INTENT_MODAL, {
    user_id: userId,
    intent: nursePreferences.startPref,
    date:
      nursePreferences.startPref === 'specific_date'
        ? nursePreferences.earliestStartDate
        : null,
    location_pref:
      nursePreferences.startPref === 'not_looking'
        ? null
        : (nursePreferences.broadPrefLocations || []).length === 0
          ? 'Anywhere'
          : nursePreferences.locations
              ?.map(loc => loc.locationString)
              .filter(x => !!x)
              .join('; '),
  });
};
