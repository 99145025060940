import { useEffect, useMemo, useState } from 'react';

import * as Yup from 'yup';
import { Field, Formik } from 'formik';

import { useMutation, useQuery } from '@src/hooks/use-graphql-hooks';
import { dateToGQLDate, dateUtils } from '@src/lib/date-io';
import { Modal } from '@src/ui';
import { FormikSubmitButton } from '@src/components/formik';
import { WelcomeBackModalHeader } from './welcome-back-modal-header';
import {
  WelcomeBackModalLocation,
  type WelcomeBackModalLocationValues,
} from './welcome-back-modal-location';
import { NavigationIntentSelector } from '../navigation-profile-dropdown/navigation-intent';
import { useSnackbarMessenger } from '@src/hooks/use-snackbar-messenger';
import { futureDate } from '@src/utils/yup';
import {
  BroadPrefLocations,
  RoleType,
  WelcomeBackModalDocument,
  WelcomeBackModalUpdateStaleIntentDocument,
} from '@generated/graphql';
import { isUser, userDisplayName } from '@src/utils/user-utils';
import { getUser } from '@src/utils/cookies';
import { nonNullValues } from '@src/utils/types';
import { MatchCriteriaModalInfoModal } from '../match-criteria/match-criteria-modal/match-criteria-modal-info-modal';
import { getInfoModalComponents, InfoType } from '@src/constants/info_modals';
import { notifyError } from '@src/lib/error-reporter';
import {
  trackUserIntentConfirmed,
  trackUserViewedIntentModal,
} from '@src/utils/segment-events';

interface WelcomeBackModalStartValues {
  startPref: string;
  earliestStartDate: Date | null;
}

type WelcomeBackModalFormValues = WelcomeBackModalStartValues &
  WelcomeBackModalLocationValues;

/* TODO - remove obsolete types and hooks related to start date */

export function WelcomeBackModal() {
  const [snackbar] = useSnackbarMessenger();
  const [startDateToggle, setStartDateToggle] = useState(false);
  const [locationToggle, setLocationToggle] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState<InfoType>();

  const cookieUser = getUser();

  const { data, loading } = useQuery(WelcomeBackModalDocument);

  const user = isUser(data?.viewer) ? data.viewer : undefined;
  const nurseProfile = user?.nurseProfile;
  const nursePreferences = nurseProfile?.nursePreferences;

  const { broadPrefLocations, locations, earliestStartDate, startPref } =
    useMemo(() => {
      return {
        earliestStartDate: nursePreferences?.earliestStartDate,
        startPref: nursePreferences?.startPref,
        broadPrefLocations: nursePreferences?.broadPrefLocations ?? [],
        locations: nursePreferences?.locations ?? [],
      };
    }, [nursePreferences]);

  const [modalToggle, setModalToggle] = useState(true);

  const handleInfoClick = (info: InfoType) => {
    setSelectedInfo(info);
    setIsInfoModalOpen(true);
  };

  const [updateStaleIntent] = useMutation(
    WelcomeBackModalUpdateStaleIntentDocument,
    {
      onCompleted(data) {
        if (data?.updateStaleIntent?.nursePreferences) {
          trackUserIntentConfirmed({
            userId: user?.id ?? '',
            updatedNursePreferences: data.updateStaleIntent.nursePreferences,
          });
        } else {
          snackbar.addErrorMessage('Something went wrong.');
          notifyError('Welcome back modal error', {
            extraSections: { metadata: { data } },
          });
        }
        setModalToggle(false);
      },
      onError(e) {
        snackbar.addErrorMessage(e.message);
      },
    }
  );

  const readyToRender = !!(
    !loading &&
    modalToggle &&
    user?.id &&
    nurseProfile?.id &&
    nursePreferences?.id &&
    nurseProfile.staleIntent &&
    !cookieUser?.isAdmin
  );

  useEffect(() => {
    if (!readyToRender) return;

    trackUserViewedIntentModal({
      userId: user.id,
      nursePreferences,
    });
  }, [readyToRender, user, nursePreferences]);

  if (!readyToRender) return null;

  const toggleLocationDate = () => {
    setLocationToggle(!locationToggle);
    if (!locationToggle && startDateToggle) setStartDateToggle(false);
  };

  const isEarliestDatePastDate =
    earliestStartDate && dateUtils.isPastDate(new Date(earliestStartDate));

  const initialValues: WelcomeBackModalFormValues = {
    startPref:
      startPref === 'specific_date' && isEarliestDatePastDate
        ? 'asap'
        : (startPref ?? 'not_looking'),
    earliestStartDate:
      earliestStartDate && !isEarliestDatePastDate
        ? (dateUtils.parseDate(earliestStartDate) ?? null)
        : null,
    locationStrings:
      broadPrefLocations.length > 0
        ? locations.map(l => l.locationString).filter(nonNullValues)
        : [],
    broadPrefLocations: broadPrefLocations,
  };

  const isNursing = nurseProfile?.role?.type === RoleType.Nursing;

  return (
    <>
      <Modal
        isOpen={true}
        styles={{
          content: {
            top: 0,
            marginTop: '8.125em',
            transform: 'translate(-50%, 0)',
            padding: '1.875rem 1.25rem',
          },
        }}
        onRequestClose={() => setModalToggle(false)}
        shouldCloseOnOverlayClick={false}
        contentLabel="Welcome Back Modal"
      >
        <div className="WelcomeBackModal">
          <WelcomeBackModalHeader
            displayName={userDisplayName(user, { last: false })}
          />
          <Formik
            validationSchema={WelcomeBackModal.validationSchema}
            initialValues={initialValues}
            onSubmit={async values => {
              await updateStaleIntent({
                variables: {
                  id: nursePreferences.id,
                  input: {
                    ...values,
                    earliestStartDate: values.earliestStartDate
                      ? dateToGQLDate(values.earliestStartDate)
                      : null,
                    broadPrefLocations:
                      (values?.broadPrefLocations as
                        | BroadPrefLocations[]
                        | undefined) ?? [],
                  },
                },
              });
            }}
          >
            {({ values, handleSubmit }) => (
              <>
                <Field name="startPref">
                  {({ form, field }: any) => (
                    <div className="WelcomeBackModalIntentSelector">
                      <NavigationIntentSelector
                        onToggle={() => {
                          const selectedStartPref = values.startPref;
                          if (selectedStartPref === 'not_looking') {
                            form.setFieldValue(field.name, 'asap');
                          } else {
                            form.setFieldValue(field.name, 'not_looking');
                          }
                        }}
                        toggle={
                          values.startPref === 'asap' ||
                          values.startPref === 'specific_date'
                        }
                        startPref={values.startPref}
                      />
                    </div>
                  )}
                </Field>

                {values.startPref !== 'not_looking' && (
                  <WelcomeBackModalLocation
                    open={locationToggle}
                    onToggle={toggleLocationDate}
                    onInfoClick={handleInfoClick}
                    isNursing={isNursing}
                  />
                )}

                <FormikSubmitButton
                  color="primary"
                  shape="rounded"
                  fluid
                  style={{ marginTop: '0.625em' }}
                  onClick={() => handleSubmit()}
                >
                  Looks good!
                </FormikSubmitButton>
              </>
            )}
          </Formik>
        </div>

        <style jsx>{`
          .WelcomeBackModal {
            display: flex;
            flex-flow: column;
            text-align: left;
          }
          .WelcomeBackModalIntentSelector {
            margin-bottom: 0.625em;
            font-weight: 500;
          }
        `}</style>
      </Modal>
      <MatchCriteriaModalInfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        modalComponents={getInfoModalComponents(selectedInfo!)}
      />
    </>
  );
}

WelcomeBackModal.validationSchema = Yup.object().shape({
  startPref: Yup.string().required(),
  earliestStartDate: Yup.mixed()
    .nullable()
    .when('startPref', {
      is: 'specific_date',
      then: futureDate()
        .label('Start date')
        .typeError(
          "Hmm. This doesn't look right! Please double-check the dates entered below."
        )
        .required(),
    }),
  locationStrings: Yup.array(Yup.string()).when(
    ['startPref', 'broadPrefLocations'],
    {
      is: (startPref: string, broadPrefLocations: string) =>
        startPref !== 'not_looking' &&
        broadPrefLocations === 'specific_locations',
      then: schema =>
        schema.required().min(1, 'At least one location is required.'),
    }
  ),
  broadPrefLocations: Yup.array(Yup.string()),
});
