import { makeVar } from '@apollo/client';
import type { ReactNode } from 'react';

export const SnackbarMessageColors = [
  'success',
  'warning',
  'danger',
  'primary',
  'info',
  'link',
] as const;
export type SnackbarMessageColor = (typeof SnackbarMessageColors)[number];

// Represents the snackbar messages generated by the system based on user interactions
export interface SnackbarMessage {
  autoHideDuration: number;
  createdAt: Date;
  id: string;
  close: boolean;
  message: ReactNode;
  color: SnackbarMessageColor;
}

export const snackbarsVar = makeVar<SnackbarMessage[]>([]);
