import { useField } from 'formik';
import { TextField } from '@material-ui/core';

import type { FieldHookConfig } from 'formik';
import type { TextFieldProps } from '@material-ui/core';

type Props = FieldHookConfig<string> & TextFieldProps;

/**
 * @formValueType `string`, or the type of the `type` property
 */
export function FormikTextField(props: Props) {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...props}
      {...field}
      InputProps={{ id: props.name, ...props.InputProps }}
      SelectProps={{ id: props.name, ...props.SelectProps }}
      InputLabelProps={{ htmlFor: props.name, ...props.InputLabelProps }}
      error={!!meta.error && meta.touched}
      helperText={!!meta.error && meta.touched ? meta.error : props.helperText}
    />
  );
}
