import { Button, Modal } from '@src/ui';

type Props = {
  modalComponents?: {
    header?: string;
    body?: string;
    linkName?: string;
    linkUrl?: string;
  };
  isOpen: boolean;
  onClose: () => void;
};

export function MatchCriteriaModalInfoModal({
  modalComponents,
  isOpen = false,
  onClose,
}: Props) {
  const modalStyles = {
    content: {
      padding: '2.5rem 3.5rem',
    },
  };
  return (
    <Modal isOpen={isOpen} styles={modalStyles}>
      {modalComponents && (
        <div className="MatchCriteriaModalInfoModalWrapper">
          {modalComponents.header && (
            <div
              className="MatchCriteriaModalModalHeader"
              data-testid="modal-info-header"
            >
              {modalComponents.header}
            </div>
          )}
          {modalComponents.body && (
            <div
              className="MatchCriteriaModalModalBody"
              data-testid="modal-info-body"
            >
              {modalComponents.body}
            </div>
          )}
          {modalComponents.linkName && (
            <div className="MatchCriteriaModalModalLink">
              <a
                href={modalComponents.linkUrl}
                target="_blank"
                rel="noreferrer"
              >
                {modalComponents.linkName}
              </a>
            </div>
          )}
          <Button
            color="primary"
            shape="rounded"
            onClick={() => onClose()}
            style={{ width: '8.9375em', height: '2em' }}
          >
            Got it!
          </Button>
          <style jsx>{`
            .MatchCriteriaModalInfoModalWrapper {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            .MatchCriteriaModalModalHeader {
              font-size: 1.25rem;
              font-weight: bold;
              padding-bottom: 1em;
            }

            .MatchCriteriaModalModalBody {
              padding-bottom: 2.5em;
              font-size: 0.875rem;
            }

            .MatchCriteriaModalModalLink {
              font-weight: bold;
              text-decoration: underline;
              padding-bottom: 1em;
            }
          `}</style>
        </div>
      )}
    </Modal>
  );
}
