import type { PropsWithChildren } from 'react';
import clsx from 'clsx';
import * as colors from '@src/support/colors';

type Props = {
  className?: string;
  intent: 'error' | 'info' | 'info-blue' | 'success' | 'warning';
  hasBorder?: boolean;
};

// NOTE: You probably want to use the <Alert /> in @src/components
export function Alert({
  className,
  intent,
  children,
  hasBorder,
}: PropsWithChildren<Props>) {
  const borderStyle = '0.0625rem solid';
  return (
    <div
      className={clsx(
        'Alert',
        {
          'Alert--intent-error': intent === 'error',
          'Alert--intent-info': intent === 'info',
          'Alert--intent-info-blue': intent === 'info-blue',
          'Alert--intent-success': intent === 'success',
          'Alert--intent-warning': intent === 'warning',
          'Alert--border': hasBorder,
          'Alert--border-error': hasBorder && intent === 'error',
          'Alert--border-info': hasBorder && intent === 'info',
          'Alert--border-info-blue': hasBorder && intent === 'info-blue',
          'Alert--border-success': hasBorder && intent === 'success',
          'Alert--border-warning': hasBorder && intent === 'warning',
        },
        className
      )}
    >
      {children}
      <style jsx>{`
        .Alert {
          border-radius: 0.25em;
          display: flex;
          font-size: 1rem;
          padding: 0.625em;
        }

        .Alert--intent-error {
          background-color: ${colors.semanticErrorLower};
          color: ${colors.semanticError};
        }

        .Alert--intent-info {
          background-color: ${colors.neutralLower};
          color: ${colors.neutralHigh};
        }

        .Alert--intent-info-blue {
          background-color: ${colors.semanticInfoLower};
          color: ${colors.semanticInfoHigh};
        }

        .Alert--intent-success {
          background-color: ${colors.semanticSuccessLower};
          color: ${colors.semanticSuccess};
        }

        .Alert--intent-warning {
          background-color: ${colors.semanticWarningLower};
          color: ${colors.semanticWarning};
        }

        .Alert--border {
          border-radius: 0.625rem;
        }

        .Alert--border-error {
          border: ${borderStyle} ${colors.semanticError};
        }
        .Alert--border-info {
          border: ${borderStyle} ${colors.neutralHigh};
        }
        .Alert--border-info-blue {
          border: ${borderStyle} ${colors.semanticInfo};
        }
        .Alert--border-success {
          border: ${borderStyle} ${colors.semanticSuccess};
        }
        .Alert--border-warning {
          border: ${borderStyle} ${colors.semanticWarning};
        }
      `}</style>
    </div>
  );
}
