import {
  type CookieOptions,
  getCookie as _getCookie,
  getCookieFromCookieString as _getCookieFromCookieString,
  getCookies as _getCookies,
  serializeCookie as _serializeCookie,
  serializeUnsetCookie as _serializeUnsetCookie,
  setCookie as _setCookie,
  unsetCookie as _unsetCookie,
} from './utils';

export { getAmplitudeSessionIdFromCookieString } from './amplitude';
export type { CookieMobile } from './mobile';
export {
  getUser,
  getUserFromCookieString,
  serializeSetUser,
  serializeUnsetUser,
  setSignUpCompleted,
  setUser,
  unsetUser,
} from './user';
export { SsoProviders, getUserSso, unsetUserSso } from './user-sso';
export type { CookieUserSso } from './user-sso';
export { getUserToken } from './user-token';

// Excludes private cookie names such as user and userToken
export enum CookieName {
  ReferringDomain = 'referringDomain',
  AfterAuthRedirect = 'afterAuthRedirect',
  PandologicEtd = 'pandologic_etd',
  SignupSource = 'signupSource',
  SignupSourceQualifier = 'signupSourceQualifier',
  Mobile = 'trusted_rn_app_info',
  Sso = 'trusted_rn_app_sso',
  userRoleType = 'userRoleType',
  // Set by 3rd party script
  AjsAnonymousId = 'ajs_anonymous_id',
  CsrfToken = 'csrfToken',
  AppcastJobId = 'appcastJobId',
  Gclid = 'gclid',
}

// Wrapping private methods to ensure only expected cookie names (CookieName enum) are allowed
export const getCookie = (cookieName: CookieName) => _getCookie(cookieName);

export const getCookieFromCookieString = (
  cookieName: CookieName,
  cookieString: string
) => _getCookieFromCookieString(cookieName, cookieString);

export const getCookies = (...cookieNames: CookieName[]) =>
  _getCookies(...cookieNames);

export const serializeCookie = (cookieName: CookieName, value: string) =>
  _serializeCookie(cookieName, value);

export const serializeUnsetCookie = (cookieName: CookieName) =>
  _serializeUnsetCookie(cookieName);

export const setCookie = (
  cookieName: CookieName,
  value: string,
  cookieOptions?: CookieOptions
) => _setCookie(cookieName, value, cookieOptions);

export const unsetCookie = (cookieName: CookieName) => _unsetCookie(cookieName);
