import { createTheme } from '@material-ui/core/styles';
import * as colors from './colors';
import { boxShadow } from '@src/support/shadows';
import { rgba } from '@src/support/rgba';

export const theme = createTheme({
  typography: {
    fontFamily: 'Grosa, helvetica, arial, sans-serif',
  },
  palette: {
    primary: {
      main: colors.neutralHighest1,
      light: colors.neutralHighest,
      dark: colors.primary01,
    },
    secondary: {
      main: colors.semanticError,
      light: colors.semanticErrorLow,
      dark: colors.semanticErrorHigh,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  overrides: {
    MuiButtonBase: {
      root: {
        outline: undefined,
      },
    },
    MuiBadge: {
      root: {
        display: 'inline',
        verticalAlign: 'baseline',
      },
      badge: {
        paddingTop: '0',
        paddingRight: '0.25em',
        paddingBottom: '0',
        paddingLeft: '0.25em',
        borderRadius: '0.9em',
        fontSize: '0.625rem',
        height: '1.8em',
        minWidth: '1.8em',
        padding: undefined,
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: colors.neutralHighest1,
        backgroundColor: colors.white,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: `${rgba(colors.black, 0.15)} 0 0 0.5em 0`,
      },
      elevation4: {
        boxShadow: `${boxShadow}`,
      },
    },
    MuiIconButton: {
      root: {
        color: colors.neutralHighest1,
        fontSize: '1em',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: colors.semanticSuccess,
      },
    },
    MuiIcon: {
      root: {
        color: colors.neutralHighest,
      },
    },
    MuiListItemIcon: {
      root: {
        color: colors.neutralHighest,
        display: 'inline-block',
        fontSize: '0.875em',
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '0.8125em',
        fontStyle: 'italic',
        lineHeight: '1.1538461538461537em',
        marginTop: '0.38461538461538464em',
        color: colors.neutralHighest,
      },
    },
    MuiSelect: {
      icon: {
        color: colors.neutralHighest,
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.neutralHighest,
      },
    },
    MuiRadio: {
      root: {
        color: colors.neutralHighest,
      },
    },
    MuiFormControl: {
      root: {
        position: 'static',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
      label: {
        color: colors.neutralHighest1,
      },
    },
    MuiInput: {
      root: {
        marginTop: '0.75em',
        marginBottom: '0.75em',
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
      underline: {
        '&:after, &:hover:not(.Mui-disabled):before': {
          borderBottom: `2px solid ${colors.neutralHighest1}`,
        },
      },
    },
    MuiInputLabel: {
      root: {
        lineHeight: '1.5em',
      },
      formControl: {
        position: 'relative',
        left: undefined,
        top: undefined,
      },
    },
    MuiFormLabel: {
      root: {
        lineHeight: '1.5em',
        textAlign: 'left',
      },
    },
  },
});
