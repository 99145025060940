import { CircularProgress, InputAdornment } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import type { PaperProps } from '@material-ui/core/Paper';
import Paper from '@material-ui/core/Paper';
import type { StandardTextFieldProps } from '@material-ui/core/TextField';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  type KeyboardEvent,
  type MouseEvent,
  type MutableRefObject,
  type ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Alert, AlertMessage } from '../alert';

const cycle = (value: number, length: number) =>
  (Math.max(value, -1) + length) % length;

const getEndAdornment = (loading: boolean, icon?: ReactNode) => {
  if (icon && !loading) {
    return (
      <InputAdornment position="start" aria-hidden>
        <a href="#">{icon}</a>
      </InputAdornment>
    );
  }
  if (loading) {
    return (
      <InputAdornment position="start" aria-hidden>
        <CircularProgress size={2} />
      </InputAdornment>
    );
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  isRelative: {
    position: 'relative',
    zIndex: 1,
    marginTop: theme.spacing(0),
  },
}));

interface OwnProps<T = string> {
  name?: string;
  icon?: ReactNode;
  inputValue?: string;
  items?: readonly T[];
  itemsToDisplay?: number;
  allowCreateValue?: boolean;
  createOptions?: {
    label?: string;
    firstOnList?: boolean;
    visible?: boolean;
    hideOnLoading?: boolean;
    showInputMinLength: number;
  };
  itemToValue?: (item?: T) => string;
  itemToKey?: (item?: T) => string;
  valueToItem?: (value: string) => T;
  loading?: boolean;
  // Called when the user selects a value ('item' will be populated if it's
  // a valid value from 'items', otherwise undefined)
  onChange?: (
    event: MouseEvent | KeyboardEvent,
    item?: T,
    userGenerated?: boolean
  ) => void;
  // Called when the user types in the input field
  onInputValueChange?: (value: string) => void;
  displayListOnFocus?: boolean;
  resultsProps?: PaperProps;
  isRelative?: boolean;
  helpAlertText?: string;
  dataCy?: string;
  textFieldSize?: 'small' | 'medium' | undefined;
  textFieldVariant?: 'filled' | 'outlined' | 'standard' | undefined;
  handleUserGeneratedState?: (value: boolean) => void;
}

const CreateMenuItem = <T,>(props: {
  isCreateVisible: boolean;
  inputRef: MutableRefObject<HTMLInputElement | undefined>;
  selectValue: NonNullable<Props<T>['onChange']>;
  valueToItem: (value: string) => T;
  label?: string;
  inputValue?: string;
}) =>
  props.isCreateVisible ? (
    <MenuItem
      component="div"
      style={{
        fontWeight: 500,
        fontSize: 13,
        whiteSpace: 'normal',
      }}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        if (props.inputRef.current) props.inputRef.current.focus();
      }}
      onClick={(event: MouseEvent) => {
        props.selectValue(event, props.valueToItem(props.inputValue!), true);
        event.preventDefault();
      }}
    >
      <span style={{ fontWeight: 700 }}>{props.label}</span> &quot;
      {props.inputValue}
      &quot;
    </MenuItem>
  ) : null;

export type Props<T> = OwnProps<T> & Omit<StandardTextFieldProps, 'onChange'>;

export const AutoComplete = <T,>({
  name,
  inputProps = {},
  inputValue,
  items = [],
  itemsToDisplay = 5,
  allowCreateValue,
  createOptions = {
    label: 'Add:',
    visible: true,
    hideOnLoading: false,
    showInputMinLength: 1,
  },
  itemToKey = (item?: T) => (item && (item as unknown as string)) || '',
  itemToValue = (item?: T) => (item && (item as unknown as string)) || '',
  valueToItem = (value: string) => value as unknown as T,
  loading = false,
  onChange,
  onInputValueChange,
  icon,
  displayListOnFocus = true,
  isRelative = false,
  resultsProps = {},
  dataCy,
  helpAlertText,
  textFieldSize,
  textFieldVariant = 'standard',
  handleUserGeneratedState,
  ...props
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState<T>();
  const inputRef = useRef<HTMLInputElement>();
  const classes = useStyles();
  const itemsIncludeInputValue = useMemo(
    () =>
      inputValue &&
      items
        .map(item => itemToValue(item).toLocaleLowerCase())
        .includes(inputValue.toLocaleLowerCase()),
    [inputValue, itemToValue, items]
  );
  inputValue &&
    items
      .map(item => itemToValue(item).toLocaleLowerCase())
      .includes(inputValue.toLocaleLowerCase());
  const inputValueHasMinLength =
    inputValue && inputValue.length >= createOptions.showInputMinLength;
  const hiddenOnLoading = createOptions.hideOnLoading && loading;
  const isCreateVisible = Boolean(
    allowCreateValue &&
      !hiddenOnLoading &&
      inputValueHasMinLength &&
      !itemsIncludeInputValue
  );

  const handleChange: StandardTextFieldProps['onChange'] = event => {
    setIsOpen(true);
    onInputValueChange?.(event.target.value);
  };

  const selectValue: Props<T>['onChange'] = (
    event,
    selected,
    isUserGenerated
  ) => {
    onChange?.(event, selected);
    setSelectedItem(selected);
    if (handleUserGeneratedState)
      handleUserGeneratedState(isUserGenerated ?? false);
    setHighlightedIndex(-1);
    setIsOpen(false);
    if (inputValue === undefined) {
      if (inputRef.current) inputRef.current.value = '';
      setSelectedItem(undefined);
    }
  };

  return (
    <div className={classes.root} data-cy={dataCy}>
      <TextField
        {...props}
        id={props.id ?? name}
        variant={textFieldVariant}
        size={textFieldSize}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            input: classes.inputInput,
          },
          endAdornment: getEndAdornment(loading, icon),
          role: 'textbox',
          title: props.id ? name : 'Search: ',
        }}
        InputLabelProps={{
          htmlFor: props.id ?? name,
          ...props.InputLabelProps,
        }}
        name={name}
        value={inputValue}
        onChange={handleChange}
        inputProps={Object.assign<
          NonNullable<StandardTextFieldProps['inputProps']>,
          StandardTextFieldProps['inputProps']
        >(
          {
            ref: inputRef,
            title: props.id ? name : 'Search: ',
            onFocus(event) {
              if (displayListOnFocus) {
                setIsOpen(true);
              }
              if (inputProps.onFocus) {
                inputProps.onFocus(event);
              }
            },
            onBlur(event) {
              setIsOpen(false);
              if (inputProps.onBlur) {
                inputProps.onBlur(event);
              }
            },
            onKeyDown(event) {
              switch (event.key) {
                case 'Escape':
                  setIsOpen(false);
                  break;
                case 'ArrowDown':
                  setHighlightedIndex(
                    cycle(highlightedIndex + 1, items.length)
                  );
                  event.preventDefault();
                  break;
                case 'ArrowUp':
                  setHighlightedIndex(
                    cycle(highlightedIndex - 1, items.length)
                  );
                  event.preventDefault();
                  break;
                case 'Enter':
                  if (highlightedIndex < 0 && items.length === 1) {
                    selectValue(event, items[0]);
                  } else {
                    selectValue(event, items[highlightedIndex]);
                  }
                  event.preventDefault();
                  break;
                default:
                  break;
              }

              if (inputProps.onKeyDown) {
                inputProps.onKeyDown(event);
              }
            },
          },
          inputProps
        )}
        data-cy="autocomplete"
      />

      {isOpen ? (
        <>
          {helpAlertText && (
            <Alert intent="warning">
              <AlertMessage smallerCopy>{helpAlertText}</AlertMessage>
            </Alert>
          )}
          <Paper
            aria-label="autocomplete"
            className={clsx(isRelative ? classes.isRelative : classes.paper)}
            square
            {...resultsProps}
          >
            <>
              {createOptions.firstOnList && (
                <CreateMenuItem
                  {...{
                    inputRef,
                    isCreateVisible,
                    selectValue,
                    valueToItem,
                    inputValue,
                    label: createOptions.label,
                  }}
                />
              )}

              {items.slice(0, itemsToDisplay).map((item, index) => {
                const isHighlighted = highlightedIndex === index;
                const label = itemToValue(item);
                const key = itemToKey(item);
                const isSelected =
                  !!selectedItem && itemToKey(selectedItem) === key;

                return (
                  <MenuItem
                    key={key}
                    selected={isHighlighted}
                    component="div"
                    style={{
                      fontWeight: isSelected ? 600 : 500,
                      fontSize: 13,
                      whiteSpace: 'normal',
                    }}
                    onMouseDown={event => {
                      event.preventDefault();
                      inputRef.current?.focus?.();
                    }}
                    onClick={event => {
                      selectValue(event, items[index]);
                      event.preventDefault();
                    }}
                    onMouseOver={() => {
                      setHighlightedIndex(index);
                    }}
                  >
                    {label}
                  </MenuItem>
                );
              })}

              {!createOptions.firstOnList && (
                <CreateMenuItem
                  {...{
                    inputRef,
                    isCreateVisible,
                    selectValue,
                    valueToItem,
                    inputValue,
                    label: createOptions.label,
                  }}
                />
              )}
            </>
          </Paper>
        </>
      ) : null}
    </div>
  );
};
