import { type ReactNode } from 'react';
import Link from 'next/link';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FontAwesomeIcon } from '@src/ui/font-awesome-icon';
import * as colors from '@src/support/colors';
import { CountBadge } from './count-badge';
import { useIsCurrentLink } from '@src/components/navigation/use-is-current-link';

export const NavigationBarLink = (props: {
  count?: number;
  href: string;
  icon: IconProp;
  activeIcon: IconProp;
  onClick?: () => void;
  rel?: string;
  target?: string;
  children: ReactNode;
}) => {
  const isCurrent = useIsCurrentLink()(props.href);

  return (
    <li className="NavigationBarLink">
      <Link
        aria-current={isCurrent}
        href={props.href}
        tabIndex={0}
        onClick={props.onClick}
        target={props.target}
        rel={props.rel}
      >
        <figure>
          <div className="icon-pill-padding">
            <div className="icon-pill">
              <CountBadge color="secondary" count={props.count}>
                <FontAwesomeIcon
                  fontSize={20}
                  icon={(isCurrent && props.activeIcon) || props.icon}
                />
              </CountBadge>
            </div>
          </div>
          <figcaption>{props.children}</figcaption>
        </figure>
      </Link>
      <style jsx>{`
        .NavigationBarLink {
          width: 70px;
        }

        .NavigationBarLink :global(figure) {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 4.5rem;
          justify-content: center;
          width: 100%;
        }

        .NavigationBarLink :global(a) {
          -webkit-tap-highlight-color: transparent;
          transition: color 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
          color: ${colors.neutralHighest};
        }

        .NavigationBarLink :global(.icon-pill-padding) {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          width: 100%;
        }

        .NavigationBarLink :global(.icon-pill) {
          display: flex;
          width: 100%;
          padding: 0.5rem;
          justify-content: center;
          transition: background-color 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
          border-radius: 1.5rem;
        }

        .NavigationBarLink :global(figcaption) {
          font-size: 0.6875rem;
          text-align: center;
        }

        .NavigationBarLink :global(a[aria-current='true']) {
          color: ${colors.black};
          font-weight: bold;
        }

        .NavigationBarLink :global(a:focus .icon-pill),
        .NavigationBarLink :global(a:hover .icon-pill) {
          background-color: ${colors.neutralLower};
        }

        .NavigationBarLink :global(a:active .icon-pill),
        .NavigationBarLink :global(a[aria-current='true'] .icon-pill) {
          background-color: ${colors.accent01Lower};
        }

        .NavigationBarLink :global(a:hover) {
          color: ${colors.black};
          text-decoration: none;
        }

        .NavigationBarLink :global(a .icon-pill svg path) {
          transition: stroke-width 0.3s cubic-bezier(0.39, 0.58, 0.57, 1);
          stroke-width: 1px;
          stroke: ${colors.black};
        }

        .NavigationBarLink :global(a:active .icon-pill svg path),
        .NavigationBarLink :global(a:focus .icon-pill svg path),
        .NavigationBarLink :global(a:hover .icon-pill svg path) {
          stroke-width: 10px;
        }
      `}</style>
    </li>
  );
};
